// import React from 'react';
// import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';


// const generateInteractivePDF = async (orderData) => {
//     // Create a new PDF document
//     const pdfDoc = await PDFDocument.create();

//     // Add the first page
//     const firstPage = pdfDoc.addPage();
//     await addFirstPageContent(pdfDoc, firstPage, orderData); // Pass pdfDoc to addFirstPageContent

//     // Save the PDF to a blob
//     const pdfBytes = await pdfDoc.save();

//     // Optionally, you can save the PDF to a file or send it to the client
//     const blob = new Blob([pdfBytes], { type: 'application/pdf' });
//     const url = URL.createObjectURL(blob);
//     window.open(url, '_blank');
// };

// const addFirstPageContent = async (pdfDoc, page, orderData) => {
//     // Embed a standard font
//     const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
//     const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
//     const timesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
//     const symbol = await pdfDoc.embedFont(StandardFonts.Symbol);



//     page.drawText('BENÜTZUNGSBEWILLIGUNG', {
//         x: 50,
//         y: page.getHeight() - 80,
//         size: 19,
//         font: helveticaBold, // Use the embedded font

//     });

//     page.drawText('UND VOLLMACHT', {
//         x: 50,
//         y: page.getHeight() - 100,
//         size: 19,
//         font: helveticaBold, // Use the embedded font
//     });

//     page.drawText('PERMISSION OF USE AND LEGAL AUTHORITY', {
//         x: 370,
//         y: page.getHeight() - 70,
//         size: 8,
//         font: helveticaBold, // Use the embedded font
//     });
//     page.drawText("PERMIS D'UTILISATION ET PROCURATION", {
//         x: 370,
//         y: page.getHeight() - 80,
//         size: 8,
//         font: helveticaBold, // Use the embedded font
//     });
//     page.drawText("AUTORIZZAZIONE DI USO DELEGA", {
//         x: 370,
//         y: page.getHeight() - 90,
//         size: 8,
//         font: helveticaBold, // Use the embedded font
//     });
//     page.drawText("LICENCIA DE USO Y AUTORIZACIÓN", {
//         x: 370,
//         y: page.getHeight() - 100,
//         size: 8,
//         font: helveticaBold, // Use the embedded font
//     });
//     page.drawText("KULLANMA IZNI VE VEKALETNAME", {
//         x: 370,
//         y: page.getHeight() - 110,
//         size: 8,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawLine({
//         start: { x: 50, y: page.getHeight() - 130 },
//         end: { x: 550, y: page.getHeight() - 130 },
//         thickness: 0.1, // Adjust line thickness as needed
//         color: rgb(0, 0, 0), // Line color
//       });


//       page.drawText("Herr/Frau", {
//         x: 50,
//         y: page.getHeight() - 140,
//         size: 7,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 150,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//       page.drawText("mr., mrs.", {
//         x: 56,
//         y: page.getHeight() - 150,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 160,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//       page.drawText("monsieur, madame", {
//         x: 56,
//         y: page.getHeight() - 160,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 170,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//       page.drawText("il (la) sig. (a)", {
//         x: 56,
//         y: page.getHeight() - 170,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 180,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//       page.drawText("bay/bayan", {
//         x: 56,
//         y: page.getHeight() - 180,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });


//     page.drawLine({
//         start: { x: 50, y: page.getHeight() - 185 },
//         end: { x: 550, y: page.getHeight() - 185 },
//         thickness: 0.1, // Adjust line thickness as needed
//         color: rgb(0, 0, 0), // Line color
//       });


//       page.drawText("Ständiger Wohnsitz", {
//         x: 50,
//         y: page.getHeight() - 195,
//         size: 7,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 205,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("permanent residence", {
//         x: 56,
//         y: page.getHeight() - 205,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 215,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("résidence habituelle", {
//         x: 56,
//         y: page.getHeight() - 215,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 225,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("domicilio permanente", {
//         x: 56,
//         y: page.getHeight() - 225,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 235,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("residencia habitual", {
//         x: 56,
//         y: page.getHeight() - 235,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 245,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("sürekli ikamet", {
//         x: 56,
//         y: page.getHeight() - 245,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });


//     page.drawLine({
//         start: { x: 50, y: page.getHeight() - 250 },
//         end: { x: 550, y: page.getHeight() - 250 },
//         thickness: 0.1, // Adjust line thickness as needed
//         color: rgb(0, 0, 0), // Line color
//       });

//       page.drawText("ist berechtig, meinen/unseren Wagen", {
//         x: 50,
//         y: page.getHeight() - 262,
//         size: 9,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 215,
//         y: page.getHeight() - 262,
//         width: 6,
//         height: 6,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//       page.drawText("is authorized to use my/our car", {
//         x: 226,
//         y: page.getHeight() - 262,
//         size: 9,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 364,
//         y: page.getHeight() - 262,
//         width: 6,
//         height: 6,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });


//       page.drawText("est autorisé(e) à conduire ma/notre voiture", {
//         x: 374,
//         y: page.getHeight() - 262,
//         size: 9,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 273,
//         width: 6,
//         height: 6,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });


//       page.drawText("è autorizzato/a guidare il mio veicolo", {
//         x: 60,
//         y: page.getHeight() - 273,
//         size: 9,
//         font: helveticaBold, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 221,
//         y: page.getHeight() - 273,
//         width: 6,
//         height: 6,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//       page.drawText("está autorizada/o para utilizar mi/nuestro auto", {
//         x: 231,
//         y: page.getHeight() - 273,
//         size: 9,
//         font: helveticaBold, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 284,
//         width: 6,
//         height: 6,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color
//       });

//       page.drawText(`benim/bizim aracimizi`, {
//         x: 60,
//         y: page.getHeight() - 284,
//         size: 9,
//         font: helveticaBold, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 122,
//         y: page.getHeight() - 278.5,
//         width: 30,
//         height: 0.7,
//         borderColor: rgb(1, 1, 1), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(1, 1, 1),    // Light gray background color
//       });


//     page.drawLine({
//         start: { x: 50, y: page.getHeight() - 290 },
//         end: { x: 550, y: page.getHeight() - 290 },
//         thickness: 0.1, // Adjust line thickness as needed
//         color: rgb(0, 0, 0), // Line color
//       });


//       page.drawText("Marke", {
//         x: 50,
//         y: page.getHeight() - 300,
//         size: 7,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 310,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("type", {
//         x: 56,
//         y: page.getHeight() - 310,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 320,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("marque", {
//         x: 56,
//         y: page.getHeight() - 320,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 330,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("marca", {
//         x: 56,
//         y: page.getHeight() - 330,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 340,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("marca", {
//         x: 56,
//         y: page.getHeight() - 340,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 350,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("marka", {
//         x: 56,
//         y: page.getHeight() - 350,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawLine({
//         start: { x: 50, y: page.getHeight() - 356 },
//         end: { x: 550, y: page.getHeight() - 356 },
//         thickness: 0.1, // Adjust line thickness as needed
//         color: rgb(0, 0, 0), // Line color
//       });


//       page.drawText("Fahrzeugkennzeichen", {
//         x: 50,
//         y: page.getHeight() - 366,
//         size: 7,
//         font: helveticaBold, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 376,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("registration number", {
//         x: 56,
//         y: page.getHeight() - 376,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 386,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("numéro d'immatriculation", {
//         x: 56,
//         y: page.getHeight() - 386,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });

//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 396,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("targato", {
//         x: 56,
//         y: page.getHeight() - 396,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });


//     page.drawRectangle({
//         x: 50,
//         y: page.getHeight() - 406,
//         width: 4,
//         height: 4,
//         borderColor: rgb(0, 0, 0), // Use the gray color for the border
//         borderWidth: 1, // Set border thickness
//         color: rgb(0, 0, 0),    // Light gray background color

//       });

//     page.drawText("número de matrícula", {
//         x: 56,
//         y: page.getHeight() - 406,
//         size: 7,
//         font: helvetica, // Use the embedded font
//     });







// }

// const PDFComponent2 = ({ orderData }) => {
//     const handleGeneratePDF = () => {
//         generateInteractivePDF(orderData);
//     };

//     return (
//         <div>
//             <button onClick={handleGeneratePDF}>Generate PDF2</button>
//         </div>
//     );
// };

// export default PDFComponent2;



import React from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'; // Import fontkit
// import ArialUnicodeMS from '../../assets/ArialUnicodeMS.TTF'; // Import the font file
// import ArialBold from '../../assets/ArialNarrowBold.ttf'
import Noto from '../../assets/NotoSans-Light.ttf'
import NotoB from '../../assets/NotoSans-Regular.ttf'
import NotoBB from '../../assets/NotoSans-Black.ttf'

const generateInteractivePDF = async (orderData, userFullName) => {
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Register fontkit
    pdfDoc.registerFontkit(fontkit);

    // Add the first page
    const firstPage = pdfDoc.addPage();
    await addFirstPageContent(pdfDoc, firstPage, orderData, userFullName); // Pass pdfDoc to addFirstPageContent

    // Save the PDF to a blob
    const pdfBytes = await pdfDoc.save();

    // Optionally, you can save the PDF to a file or send it to the client
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, 'pdfWindow', 'width=800,height=600');
};

const addFirstPageContent = async (pdfDoc, page, orderData, userFullName) => {
    // Load and embed a custom font that supports Turkish characters
    const fontBytes = await fetch(NotoB).then(res => res.arrayBuffer());
    const fontBytes2 = await fetch(NotoBB).then(res => res.arrayBuffer());
    const fontBytes3 = await fetch(Noto).then(res => res.arrayBuffer());
    const fontBytes4 = await fetch(NotoB).then(res => res.arrayBuffer());
    const fontBytes5 = await fetch(NotoBB).then(res => res.arrayBuffer());

    const customFont = await pdfDoc.embedFont(fontBytes);
    const customFont2 = await pdfDoc.embedFont(fontBytes2);
    const customFont3 = await pdfDoc.embedFont(fontBytes3);
    const customFont4 = await pdfDoc.embedFont(fontBytes4);
    const customFont5 = await pdfDoc.embedFont(fontBytes5);

    const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    // const timesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    // Set the font and draw text
    page.setFont(customFont);
    // page.setFontSize(12);
    // page.drawText('Türkçe metin örneği: Çalışan insanlar', {
    //     x: 50,
    //     y: page.getHeight() - 50,
    //     color: rgb(0, 0, 0),
    // });


    page.drawText('BENÜTZUNGSBEWILLIGUNG', {
        x: 50,
        y: page.getHeight() - 55,
        size: 19,
        font: customFont2,


    });

    page.drawText('UND VOLLMACHT', {
        x: 50,
        y: page.getHeight() - 75,
        size: 19,
        font: customFont2,
    });

    page.drawText('PERMISSION OF USE AND LEGAL AUTHORITY', {
        x: 370,
        y: page.getHeight() - 45,
        size: 8,
        font: customFont2,
    });
    page.drawText("PERMIS D'UTILISATION ET PROCURATION", {
        x: 370,
        y: page.getHeight() - 55,
        size: 8,
        font: customFont2,
    });
    page.drawText("AUTORIZZAZIONE DI USO DELEGA", {
        x: 370,
        y: page.getHeight() - 65,
        size: 8,
        font: customFont2,
    });
    page.drawText("LICENCIA DE USO Y AUTORIZACIÓN", {
        x: 370,
        y: page.getHeight() - 75,
        size: 8,
        font: customFont2,
    });
    page.drawText("KULLANMA IZNI VE VEKALETNAME", {
        x: 370,
        y: page.getHeight() - 85,
        size: 8,
        font: customFont2,
    });


    page.drawLine({
        start: { x: 50, y: page.getHeight() - 100 },
        end: { x: 550, y: page.getHeight() - 100 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    page.drawText("Herr/Frau", {
        x: 50,
        y: page.getHeight() - 110,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 120,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("mr., mrs.", {
        x: 56,
        y: page.getHeight() - 120,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 130,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("monsieur, madame", {
        x: 56,
        y: page.getHeight() - 130,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 140,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("il (la) sig. (a)", {
        x: 56,
        y: page.getHeight() - 140,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 150,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("la sra./ el sr.", {
        x: 56,
        y: page.getHeight() - 150,
        size: 7,
    });



    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 160,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("bay/bayan", {
        x: 56,
        y: page.getHeight() - 160,
        size: 7,
    });




    page.drawLine({
        start: { x: 50, y: page.getHeight() - 165 },
        end: { x: 550, y: page.getHeight() - 165 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    page.drawText("Ständiger Wohnsitz", {
        x: 50,
        y: page.getHeight() - 175,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 185,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("permanent residence", {
        x: 56,
        y: page.getHeight() - 185,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 195,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("résidence habituelle", {
        x: 56,
        y: page.getHeight() - 195,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 205,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("domicilio permanente", {
        x: 56,
        y: page.getHeight() - 205,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 215,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("residencia habitual", {
        x: 56,
        y: page.getHeight() - 215,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 225,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("sürekli ikamet", {
        x: 56,
        y: page.getHeight() - 225,
        size: 7,
    });


    page.drawLine({
        start: { x: 50, y: page.getHeight() - 230 },
        end: { x: 550, y: page.getHeight() - 230 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });

    page.drawText("ist berechtig, meinen/unseren Wagen", {
        x: 50,
        y: page.getHeight() - 242,
        size: 6.8,
        font: customFont2,
    });


    page.drawRectangle({
        x: 186,
        y: page.getHeight() - 242,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("is authorized to use my/our car", {
        x: 195,
        y: page.getHeight() - 242,
        size: 6.8,
        font: customFont2,
    });


    page.drawRectangle({
        x: 308,
        y: page.getHeight() - 242,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });


    page.drawText("est autorisé(e) à conduire ma/notre voiture", {
        x: 318,
        y: page.getHeight() - 242,
        size: 6.8,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 253,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });


    page.drawText("è autorizzato/a guidare il mio veicolo", {
        x: 60,
        y: page.getHeight() - 253,
        size: 6.8,
        font: customFont2,
    });

    page.drawRectangle({
        x: 193,
        y: page.getHeight() - 253,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("está autorizada/o para utilizar mi/nuestro auto", {
        x: 202,
        y: page.getHeight() - 253,
        size: 6.8,
        font: customFont2,
    });

    page.drawRectangle({
        x: 371,
        y: page.getHeight() - 253,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color
    });

    page.drawText(`benim/bizim aracımızı`, {
        x: 380,
        y: page.getHeight() - 253,
        size: 6.8,
        font: customFont5,
    });

    // page.drawRectangle({
    //     x: 122,
    //     y: page.getHeight() - 278.5,
    //     width: 30,
    //     height: 0.7,
    //     borderColor: rgb(1, 1, 1), // Use the gray color for the border
    //     borderWidth: 1, // Set border thickness
    //     color: rgb(1, 1, 1),    // Light gray background color
    //   });


    page.drawLine({
        start: { x: 50, y: page.getHeight() - 260 },
        end: { x: 550, y: page.getHeight() - 260 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    page.drawText("Marke", {
        x: 50,
        y: page.getHeight() - 270,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 280,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("type", {
        x: 56,
        y: page.getHeight() - 280,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 290,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("marque", {
        x: 56,
        y: page.getHeight() - 290,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 300,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("marca", {
        x: 56,
        y: page.getHeight() - 300,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 310,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("marca", {
        x: 56,
        y: page.getHeight() - 310,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 320,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("marka", {
        x: 56,
        y: page.getHeight() - 320,
        size: 7,
    });

    page.drawLine({
        start: { x: 50, y: page.getHeight() - 326 },
        end: { x: 550, y: page.getHeight() - 326 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    page.drawText("Fahrzeugkennzeichen", {
        x: 50,
        y: page.getHeight() - 336,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 346,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("registration number", {
        x: 56,
        y: page.getHeight() - 346,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 356,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("numéro d'immatriculation", {
        x: 56,
        y: page.getHeight() - 356,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 366,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("targato", {
        x: 56,
        y: page.getHeight() - 366,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 376,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("número de matrícula", {
        x: 56,
        y: page.getHeight() - 376,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 386,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("resmi plaka numarası", {
        x: 56,
        y: page.getHeight() - 386,
        size: 7,
        font: customFont,
    });

    page.drawLine({
        start: { x: 50, y: page.getHeight() - 392 },
        end: { x: 550, y: page.getHeight() - 392 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    page.drawText("in Verbindung mit Zulassungsbescheinigung *)", {
        x: 50,
        y: page.getHeight() - 402,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 412,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("in connection with registration certificate", {
        x: 56,
        y: page.getHeight() - 412,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 422,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("associé au certificat d'immatriculation", {
        x: 56,
        y: page.getHeight() - 422,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 432,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("con carta di circolazione", {
        x: 56,
        y: page.getHeight() - 432,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 442,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("juntamente con el certificado de matriculación ", {
        x: 56,
        y: page.getHeight() - 442,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 452,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("tescil belgesiye bağlantılı olarak", {
        x: 56,
        y: page.getHeight() - 452,
        size: 7,
        font: customFont,
    });









    page.drawLine({
        start: { x: 50, y: page.getHeight() - 458 },
        end: { x: 550, y: page.getHeight() - 458 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    page.drawText("ausgestellt auf", {
        x: 50,
        y: page.getHeight() - 468,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 478,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("issued to", {
        x: 56,
        y: page.getHeight() - 478,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 488,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("delivré à", {
        x: 56,
        y: page.getHeight() - 488,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 498,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("rilasciato/a a", {
        x: 56,
        y: page.getHeight() - 498,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 508,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("expedido a", {
        x: 56,
        y: page.getHeight() - 508,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 518,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("teşir üzerine", {
        x: 56,
        y: page.getHeight() - 518,
        size: 7,
        font: customFont,
    });





    page.drawText("HRW Motorrad GmbH", {
        x: 230,
        y: page.getHeight() - 485,
        size: 14,
        font: helvetica,
    });
    page.drawText("Maria-Gailer-Straße 38a, 9500 Villach", {
        x: 230,
        y: page.getHeight() - 505,
        size: 14,
        font: helvetica,
    });





    page.drawLine({
        start: { x: 50, y: page.getHeight() - 524 },
        end: { x: 550, y: page.getHeight() - 524 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });

    page.drawText("für Fahrten außerhalb Österreichs zu benützen und sämtliche Zollformalitäten für mich/uns zu unterzeichen und zu erledigen.", {
        x: 50,
        y: page.getHeight() - 534,
        size: 6.8,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 544,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color
    });

    page.drawText("for journeys abroad and to sign and handle all customs formalities for me/us.", {
        x: 60,
        y: page.getHeight() - 544,
        size: 6.8,
        font: customFont2,
    });

    page.drawRectangle({
        x: 335,
        y: page.getHeight() - 544,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color
    });

    page.drawText("en cas de voyage à l'étranger ainsi qu'à signer et à", {
        x: 345,
        y: page.getHeight() - 544,
        size: 6.8,
        font: customFont2,
    });

    page.drawText("effectuer toytes les formalités douaniéres pour moi/nous.", {
        x: 50,
        y: page.getHeight() - 554,
        size: 6.8,
        font: customFont2,
    });

    page.drawRectangle({
        x: 256,
        y: page.getHeight() - 554,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color
    });

    page.drawText("per viaggi fuori dei confini dell'Austria e delega di firmare ed eseguire tutte", {
        x: 265,
        y: page.getHeight() - 554,
        size: 6.8,
        font: customFont2,
    });

    page.drawText("le formalità doga-nali per mio/nostro conto.", {
        x: 50,
        y: page.getHeight() - 564,
        size: 6.8,
        font: customFont2,
    });

    page.drawRectangle({
        x: 207,
        y: page.getHeight() - 564,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color
    });

    page.drawText("para viajar al extranjero y para completar y firmar por mí/nostros todos los tràmites", {
        x: 216,
        y: page.getHeight() - 564,
        size: 6.8,
        font: customFont2,
    });

    page.drawText("aduaneros necesarios.", {
        x: 50,
        y: page.getHeight() - 574,
        size: 6.8,
        font: customFont2,
    });

    page.drawRectangle({
        x: 133,
        y: page.getHeight() - 574,
        width: 6,
        height: 6,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color
    });

    page.drawText("yurtdışında yapacağı yolculuklarda kullanma ve ulusal gümrük formalitelerini benim/bizim için imzalama", {
        x: 143,
        y: page.getHeight() - 574,
        size: 6.8,
        font: customFont2,
    });

    page.drawText("ve tamamlama yetkinliğine sahiptir.", {
        x: 50,
        y: page.getHeight() - 584,
        size: 6.8,
        font: customFont2,
    });








    page.drawLine({
        start: { x: 50, y: page.getHeight() - 590 },
        end: { x: 550, y: page.getHeight() - 590 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });

    page.drawText("Ort, Datum", {
        x: 50,
        y: page.getHeight() - 600,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 610,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("place, date", {
        x: 56,
        y: page.getHeight() - 610,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 620,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("ville, date", {
        x: 56,
        y: page.getHeight() - 620,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 630,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("luogo, data", {
        x: 56,
        y: page.getHeight() - 630,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 640,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("lugar, fecha", {
        x: 56,
        y: page.getHeight() - 640,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 650,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("yer, tarih", {
        x: 56,
        y: page.getHeight() - 650,
        size: 7,
        font: customFont,
    });






    page.drawLine({
        start: { x: 50, y: page.getHeight() - 656 },
        end: { x: 550, y: page.getHeight() - 656 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });

    page.drawText("Unterschrift und eventuell Firmenstempel des Kfz-Inhabers", {
        x: 50,
        y: page.getHeight() - 666,
        size: 7,
        font: customFont2,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 676,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("signature and if possible official seal of the car owner", {
        x: 56,
        y: page.getHeight() - 676,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 686,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("signature et éventuellement cachet commercial du propriétaire de la voiture", {
        x: 56,
        y: page.getHeight() - 686,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 696,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("firma del proprietario del veicolo ed eventuale timbro dell'azienda", {
        x: 56,
        y: page.getHeight() - 696,
        size: 7,
    });


    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 706,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("firma y, en lo posible, sello comercial del titular del vehículo", {
        x: 56,
        y: page.getHeight() - 706,
        size: 7,
    });

    page.drawRectangle({
        x: 50,
        y: page.getHeight() - 716,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("araç sahibinin imzası veya firma mührü", {
        x: 56,
        y: page.getHeight() - 716,
        size: 7,
        font: customFont,
    });


    page.drawLine({
        start: { x: 50, y: page.getHeight() - 760 },
        end: { x: 550, y: page.getHeight() - 760 },
        thickness: 0.1, // Adjust line thickness as needed
        color: rgb(0, 0, 0), // Line color
    });


    page.drawText("*) bzw. carnet de passages en douane, wenn benötigt", {
        x: 50,
        y: page.getHeight() - 770,
        size: 6.6,
        font: customFont,
    });

    page.drawRectangle({
        x: 220,
        y: page.getHeight() - 770,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("and/or carnet de passages en douane, if required", {
        x: 227,
        y: page.getHeight() - 770,
        size: 6.6,
        font: customFont,
    });

    page.drawRectangle({
        x: 384,
        y: page.getHeight() - 770,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("respectivement carnet de passages en douane,", {
        x: 390,
        y: page.getHeight() - 770,
        size: 6.6,
        font: customFont,
    });

    page.drawText("si nécessaire", {
        x: 50,
        y: page.getHeight() - 780,
        size: 6.6,
        font: customFont,
    });

    page.drawRectangle({
        x: 94,
        y: page.getHeight() - 780,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("e/o il carnet de passages en douane, se necessario", {
        x: 101,
        y: page.getHeight() - 780,
        size: 6.6,
        font: customFont,
    });

    page.drawRectangle({
        x: 262,
        y: page.getHeight() - 780,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("y/o carnet de passages en douane, si necesario", {
        x: 271,
        y: page.getHeight() - 780,
        size: 6.6,
        font: customFont,
    });

    page.drawRectangle({
        x: 421,
        y: page.getHeight() - 780,
        width: 4,
        height: 4,
        borderColor: rgb(0, 0, 0), // Use the gray color for the border
        borderWidth: 1, // Set border thickness
        color: rgb(0, 0, 0),    // Light gray background color

    });

    page.drawText("veya carnet de passages en douane,", {
        x: 428,
        y: page.getHeight() - 780,
        size: 6.6,
        font: customFont,
    });

    page.drawText("gerekirse", {
        x: 50,
        y: page.getHeight() - 790,
        size: 6.6,
        font: customFont,
    });



    page.drawText(`${orderData.firstName} ${orderData.lastName}`, {
        x: 230,
        y: page.getHeight() - 140,
        size: 14,
        font: helvetica,
    });


    page.drawText(`${orderData.street} ${orderData.houseNumber}, ${orderData.zipCode} ${orderData.city}`, {
        x: 230,
        y: page.getHeight() - 204,
        size: 14,
        font: helvetica,
    });





    {
        orderData.statusMotorcycle1 && (
            page.drawText(`${orderData.motorcycle1.marke} ${orderData.motorcycle1.modell}`, {
                x: 230,
                y: page.getHeight() - 300, // Adjust y position to be inside the border
                size: 14,
                font: helvetica, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }

    {
        orderData.statusMotorcycle2 && (
            page.drawText(`${orderData.motorcycle2.marke} ${orderData.motorcycle2.modell}`, {
                x: 230,
                y: page.getHeight() - 300, // Adjust y position to be inside the border
                size: 14,
                font: helvetica, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }

    {
        orderData.statusMotorcycle1 && (
            page.drawText(orderData.motorcycle1.kennzeichen, {
                x: 230,
                y: page.getHeight() - 365, // Adjust y position to be inside the border
                size: 14,
                font: helvetica, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }

    {
        orderData.statusMotorcycle2 && (
            page.drawText(orderData.motorcycle2.kennzeichen, {
                x: 230,
                y: page.getHeight() - 365, // Adjust y position to be inside the border
                size: 14,
                font: helvetica, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }

    {
        orderData.statusMotorcycle1 && (
            page.drawText(orderData.motorcycle1.certificateNumber, {
                x: 230,
                y: page.getHeight() - 430, // Adjust y position to be inside the border
                size: 14,
                font: helvetica, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }

    {
        orderData.statusMotorcycle2 && (
            page.drawText(orderData.motorcycle2.certificateNumber, {
                x: 230,
                y: page.getHeight() - 430, // Adjust y position to be inside the border
                size: 14,
                font: helvetica, // Use the embedded font
                color: rgb(0, 0, 0), // Use the gray color for the text
            })
        )
    }



    // const form = pdfDoc.getForm();

    // // Create a text field
    // const textField = form.createTextField('myTextField');

    // // Add text field to the page with default appearance properties
    // textField.addToPage(page, {
    //     x: 230,
    //     y: page.getHeight() - 457,
    //     width: 320,
    //     height: 64,
    //     textColor: rgb(0, 0, 0), // Setting text color
    //     borderColor: rgb(1, 1, 1), // Setting border color
    //     borderWidth: 1,
    //     padding: 5,
    //     da: '/Helv 14 Tf 0 g', // Default appearance properties
    // });

    // // Set font size (if necessary, although it might not be needed here)
    // textField.setFontSize(14);


    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = today.getFullYear();

    const formattedDate = `${day}.${month}.${year}`;

    page.drawText(`Villach, ${formattedDate}`, {
        x: 230,
        y: page.getHeight() - 630,
        size: 14,
        font: helvetica,
    });




    // Create a text field
    // const textField2 = form.createTextField('myTextField2');

    // // Add text field to the page with default appearance properties
    // textField2.addToPage(page, {
    //     x: 280,
    //     y: page.getHeight() - 645,
    //     width: 270,
    //     height: 64,
    //     textColor: rgb(0, 0, 0), // Setting text color
    //     borderColor: rgb(1, 1, 1), // Setting border color
    //     borderWidth: 1,
    //     padding: 5,
    //     da: '/Helv 14 Tf 0 g', // Default appearance properties
    // });

    // Set font size (if necessary, although it might not be needed here)
    // textField2.setFontSize(14);

    page.drawText(`Buchungs-ID: ${orderData.id} | Seite 1/1`, {
        x: 453,
        y: page.getHeight() - 800, // Позиция текста
        size: 8,
        font: helvetica, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
    });


};

// const PDFComponent2 = ({ orderData }) => {
//     const handleGeneratePDF = () => {
//         generateInteractivePDF(orderData);
//     };

const API_BASE_URL = 'https://admin.motorrad-mieten-kaernten.at';

const PDFComponent2 = ({ orderData, userFullName }) => {
    const handleGeneratePDF = async () => {
        try {
            const now = new Date();
            now.setHours(now.getHours() + 2);

            // Шаг 1: Создание новой записи
            const response = await fetch(`${API_BASE_URL}/items/records/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderId: orderData.id,
                    name: userFullName,
                    docType: 'Benutzungsbewilligung',
                    date: now.toISOString(),
                }),
            });

            if (!response.ok) {
                throw new Error('Ошибка при создании записи');
            }

            const newRecord = await response.json();
            console.log('Запись успешно создана:', newRecord);

            const newRecordId = newRecord.id || newRecord.data?.id;
            if (!newRecordId) {
                throw new Error('ID новой записи не найден');
            }

            // Шаг 2: Проверка на существование связи
            const exists = await checkRecordExists(orderData.id, newRecordId);
            if (exists) {
                console.log('Запись уже существует для данного заказа:', newRecordId);
                return; // Выходим, если связь уже существует
            }

            // Шаг 3: Обновляем промежуточную таблицу Orders_records
            await updateOrdersRecords(orderData.id, newRecordId);
            generateInteractivePDF(orderData);

            // Шаг 4: Добавляем ID в массив записей заказа
            // await updateOrderRecords(orderData.id, newRecordId);

        } catch (error) {
            console.error('Ошибка:', error);
        }
    };

    const checkRecordExists = async (orderId, newRecordId) => {
        try {
            const currentRecordsResponse = await fetch(`${API_BASE_URL}/items/Orders/${orderId}?fields=records`);
            const currentRecordsData = await currentRecordsResponse.json();

            const currentRecords = Array.isArray(currentRecordsData.data.records) ? currentRecordsData.data.records : [];
            return currentRecords.includes(newRecordId);
        } catch (error) {
            console.error('Ошибка при проверке существования записи:', error);
            return false;
        }
    };

    const updateOrdersRecords = async (orderId, newRecordId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/items/Orders_records_1`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Orders_id: orderId,
                    records_id: newRecordId,
                }),
            });

            if (!response.ok) {
                throw new Error('Ошибка при обновлении промежуточной таблицы');
            }

            const result = await response.json();
            console.log('Промежуточная таблица успешно обновлена:', result);

        } catch (error) {
            console.error('Ошибка:', error);
        }
    };

    return (
        <div>
            <button className='panelBtnFilterFix' onClick={handleGeneratePDF}>BENÜTZUNGSBEWILLIGUNG</button>
        </div>
    );
};

export default PDFComponent2;

