// import React from 'react';
// import { PDFViewer, Document, Page, Text, StyleSheet, Image, View } from '@react-pdf/renderer';
// import { format } from 'date-fns';
// import logo from '../../assets/megabike_logo.png'


// // Стили для PDF-документа
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'column',
//     paddingTop: 20,
//     paddingBottom: 20,
//     paddingHorizontal: 40,
//   },
//   header: {
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     marginBottom: 20,
//   },
//   logo: {
//     width: 80,
//     height: 40,
//   },
//   heading: {
//     fontSize: 18,
//     fontWeight: 'bold',
//     color: '#1f4f82',
//     textAlign: 'center',
//     marginVertical: 10,
//   },
//   table: {
//     width: '100%',
//     borderWidth: 1,
//     borderColor: '#cccccc',
//     borderRadius: 5,
//     marginBottom: 10,
//     overflow: 'hidden',
//   },
//   tableRow: {
//     flexDirection: 'row',
//     borderBottomWidth: 1,
//     borderBottomColor: '#cccccc',
//   },
//   tableCellHeader: {
//     backgroundColor: '#f0f0f0',
//     padding: 8,
//     fontSize: 12,
//     fontWeight: 'bold',
//     flex: 1,
//     textAlign: 'left',
//     borderRightWidth: 1,
//     borderRightColor: '#cccccc',
//   },
//   tableCell: {
//     padding: 8,
//     fontSize: 12,
//     flex: 1,
//     textAlign: 'left',
//     borderRightWidth: 1,
//     borderRightColor: '#cccccc',
//   },
//   tableCellWide: {
//     flex: 2,
//   },
//   noBorder: {
//     borderRightWidth: 0,
//   },
//   signatureContainer: {
//     borderWidth: 1,
//     borderColor: '#000000',
//     borderRadius: 5,
//     height: 60,
//     width: 300,
//     justifyContent: 'center',
//     alignItems: 'center',
//     display: 'flex',
//     alignSelf: 'flex-end',
//     marginTop: 30,
//   },
//   signatureText: {
//     fontSize: 10,
//     color: 'gray',
//     alignSelf: 'flex-start',
//     marginLeft: 5,
//     marginTop: 5,
//   },
// });

// const MyDocument = ({ orderData }) => (
//   <Document>
//     {/* Первая страница */}
//     <Page style={styles.page}>
//       <View style={styles.header}>
//         <Image style={styles.logo} src={logo} />
//         <Text style={styles.heading}>Bestellformular</Text>
//       </View>
//       <View style={styles.table}>
//         {orderData.statusMotorcycle1 && (

//           <View style={styles.tableRow}>
//             <Text style={[styles.tableCellHeader, styles.tableCellWide]}>Motorrad</Text>
//             <Text style={styles.tableCell}>{orderData.selectedMotorcycle}</Text>
//           </View>
//         )}
//         {orderData.statusMotorcycle2 && (
//           <View style={styles.tableRow}>
//             <Text style={[styles.tableCellHeader, styles.tableCellWide]}>Motorrad</Text>
//             <Text style={styles.tableCell}>{orderData.selectedMotorcycle2}</Text>
//           </View>
//         )}

//         <View style={styles.tableRow}>
//           <Text style={[styles.tableCellHeader, styles.tableCellWide]}>Ausgewählte Rate</Text>
//           <Text style={styles.tableCell}>{orderData.selectedRate}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Startdatum</Text>
//           <Text style={styles.tableCell}>{format(new Date(orderData.startDate), 'dd/MM/yyyy')}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Enddatum</Text>
//           <Text style={styles.tableCell}>{format(new Date(orderData.endDate), 'dd/MM/yyyy')}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Startzeit</Text>
//           <Text style={styles.tableCell}>{orderData.startTime}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Endzeit</Text>
//           <Text style={styles.tableCell}>{orderData.endTime}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Anzahl der Tage</Text>
//           <Text style={styles.tableCell}>{orderData.days}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           {orderData.statusMotorcycle1 && (
//             <>
//               <Text style={styles.tableCellHeader}>Preis (€)</Text>
//               <Text style={[styles.tableCell, styles.noBorder]}>{orderData.price}</Text>
//             </>
//           )}
//           {orderData.statusMotorcycle2 && (
//             <>
//               <Text style={styles.tableCellHeader}>Preis (€)</Text>
//               <Text style={[styles.tableCell, styles.noBorder]}>{orderData.price2}</Text>
//             </>
//           )}
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={[styles.tableCellHeader, styles.tableCellWide]}>Zusatzpaket</Text>
//           <Text style={styles.tableCell}>{orderData.zusatzpaket ? 'Ja' : 'Nein'}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={[styles.tableCellHeader, styles.tableCellWide]}>Zusätzliche Informationen</Text>
//           <Text style={styles.tableCell}>{orderData.additionalInfo}</Text>
//         </View>
//       </View>
//       <View style={styles.signatureContainer}>
//         <Text style={styles.signatureText}>Unterschrift (Seite 1)</Text>
//       </View>
//     </Page>

//     {/* Вторая страница */}
//     <Page style={styles.page}>
//       <View style={styles.header}>
//         <Image style={styles.logo} src={logo} />
//         <Text style={styles.heading}>Bestellformular</Text>
//       </View>
//       <View style={styles.table}>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Dokumenttyp</Text>
//           <Text style={styles.tableCell}>{orderData.documentType}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Dokumentnummer</Text>
//           <Text style={styles.tableCell}>{orderData.documentNumber}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Land</Text>
//           <Text style={styles.tableCell}>{orderData.country}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Anrede</Text>
//           <Text style={styles.tableCell}>{orderData.anrede}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Vorname</Text>
//           <Text style={styles.tableCell}>{orderData.firstName}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Nachname</Text>
//           <Text style={styles.tableCell}>{orderData.lastName}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Geburtsdatum</Text>
//           <Text style={styles.tableCell}>{orderData.birthDate}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Straße</Text>
//           <Text style={styles.tableCell}>{orderData.street}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Hausnummer</Text>
//           <Text style={styles.tableCell}>{orderData.houseNumber}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Postleitzahl</Text>
//           <Text style={styles.tableCell}>{orderData.zipCode}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Stadt</Text>
//           <Text style={styles.tableCell}>{orderData.city}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>E-Mail</Text>
//           <Text style={styles.tableCell}>{orderData.email}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Telefonnummer</Text>
//           <Text style={styles.tableCell}>{orderData.phoneNumber}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Führerscheinnummer</Text>
//           <Text style={styles.tableCell}>{orderData.driverLicenseNumber}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Führerscheinklasse</Text>
//           <Text style={styles.tableCell}>{orderData.driverLicenseClass}</Text>
//         </View>
//         <View style={styles.tableRow}>
//           <Text style={styles.tableCellHeader}>Führerschein seit</Text>
//           <Text style={[styles.tableCell, styles.noBorder]}>{orderData.driverLicenseSince}</Text>
//         </View>
//       </View>
//       <View style={styles.signatureContainer}>
//         <Text style={styles.signatureText}>Unterschrift (Seite 2)</Text>
//       </View>
//     </Page>
//   </Document>
// );

// const PDFComponent = ({ orderData }) => (
//   <PDFViewer width="900" height="1200">
//     <MyDocument orderData={orderData} />
//   </PDFViewer>
// );

// export default PDFComponent;



import React from 'react';
import { PDFDocument, rgb, setLineHeight, StandardFonts } from 'pdf-lib';
import imageUrl from '../../assets/mark2.png';
import imageUrl1 from '../../assets/mot.png';
import imageUrl2 from '../../assets/circle.png';
import imageUrl3 from '../../assets/megabikeL.png';
import { getOrders, updateRecord } from '../../services/apiService';
import { useLocation } from 'react-router-dom';





// Function to add content to the first page



const addSecondPageContent = async (pdfDoc, page, orderData) => {
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontSize = 9;
  const margin = 75;
  const columnWidth = (page.getWidth() - 2 * margin) / 2;
  const startY = page.getHeight() - 80;

  const maxWidth = 200;
  const xColumn1 = 40; // Начало первой колонки
  const xColumn2 = page.getWidth() / 2 + 10; // Начало второй колонки

  const yPosition = page.getHeight() - 70;


  const imageResponse3 = await fetch(imageUrl3);
  const imageBytes3 = await imageResponse3.arrayBuffer();

  // Embed the image in the PDF
  const image3 = await pdfDoc.embedPng(imageBytes3);

  const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);




  // Draw the image on the PDF page
  page.drawImage(image3, {
    x: 365,
    y: 750,
    width: 190,
    height: 40,
  });

  // Draw a horizontal line (HR)
  page.drawLine({
    start: { x: 40, y: 740 },
    end: { x: 555, y: 740 },
    thickness: 0.1, // Adjust line thickness as needed
    color: rgb(0, 0, 0), // Line color
  });





  page.drawText(`Vertragsbedingungen:`, {
    x: 40,
    y: page.getHeight() - 115, // Позиция текста
    size: 8,
    font: fontBold, // Встроенный шрифт
    color: rgb(0, 0, 0), // Цвет текста
  });











  // Define the text to be split into two columns
  const longTextColumn1 = `1) Das Mietverhältnis ist für die vertraglich festgesetzte Zeit vereinbart. Die Rückführung des Fahrzeuges hat der Mieter an die Adresse des Vermieters bzw. an den Ort durchzuführen, der mit der Fuhrparkverwaltung des Vermieters vereinbart wurde. Bei nicht rechtzeitiger Rückgabe ist der Mieter für jeden Schaden haftbar, der dem Vermieter aus der Vorenthaltung des Besitzes entsteht. Der Mieter erkennt den mangelfreien und verkehrssicheren Zustand des Fahrzeuges, seiner Ausrüstung (z.B. Werkzeug) sowie den angeführten km-Stand ausdrücklich an. Der Mieter verzichtet auf jede Geltendmachung von durch Ausfallen des Mietfahrzeuges entstandenen Kosten oder Verdienstentgang. Der Mieter ist verpflichtet, den Wert von nicht zurückgegebenen Bestandteilen, Werkzeug und dergleichen bei der Rückgabe des Fahrzeuges zu bezahlen. Das Fahrzeug wird getankt und in sauberem Zustand übergeben und ist dementsprechend zurückzugeben.`;
  const longTextColumn2 = `2) Die Überlassung des Fahrzeuges an einen unberechtigten (im Mietvertrag nicht angeführten) Dritten ist nicht gestattet. Ein Verstoß gegen diese Bestimmung hat zur Folge, dass der Mieter für die bei der Fahrt durch unberechtigte Dritte entstandenen Haftpflicht- und Fahrzeugschäden selbst aufkommen muss, bzw. sich gegenüber der Versicherungsgesellschaft des Vermieters regresspflichtig macht. Der Mieter verpflichtet sich zum jederzeitigen Nachweis darüber, wer das Fahrzeug jeweils gefahren hat, auch für den Fall, dass die Überlassung des Fahrzeuges an den Fahrer nicht erlaubt war. Bei Verstoß dagegen haftet der Mieter für den Fall, dass der Fahrer nicht ermittelt werden kann, für jeglichen Schaden, der dem Vermieter aus dieser Unterlassung entsteht. `;
  const longTextColumn3 = `3) Das Mietfahrzeug ist auf den Vermieter zugelassen und von diesem gegen Haftpflicht bis zu einem Pauschal-Höchstbetrag von €15.000.000,00 versichert. Durch die Versicherung nicht abgedeckte Schäden gehen zu Lasten des Mieters. Sollte der Mieter mit dem Fahrzeug an einem Verkehrsereignis beteiligt sein, welches als Versicherungsfall anzusehen ist, hat er die Fuhrpark-Verwaltung des Vermieters längstens binnen 48 Stunden von dem Unfall zu verständigen. Der Bericht an den Vermieter soll in Form des Europäischen Unfallberichtes erfolgen und auf jeden Fall folgenden Angaben enthalten:`;
  const longTextColumn33 = `- Datum und Uhrzeit des Unfalles - Ort des Unfalles - Adresse des Fahrers des Fahrzeuges zum Zeitpunkt des Unfalles - Angaben über Führerschein des Fahrers (Klasse, ausstellende Behörde, Ausstellungsdatum) - Adresse der anderen am Unfall beteiligten Personen und Kennzeichen des/der am Unfall beteiligten Fahrzeugs/Fahrzeuge - Detaillierter Bericht über den Unfall (einschließlich Zeichnung) sowie Namen und Adressen möglicher Zeugen - Schadensausmaß (Verletzung, Tod, Sachschaden) - Angabe über den Ort, an dem sich das Fahrzeug befindet Schadenserhebungen dürfen erst nach Einholung der Zustimmung des Vermieters veranlasst werden. `;
  const longTextColumn4 = `4) Fahrten sind innerhalb Österreichs vollkaskoversichert, mit einem fix definierten Selbstbehalt. Für Fahrten außerhalb Österreichs besteht kein Versicherungsschutz. Das Fahrzeug darf nicht für Renn- und Zuverlässigkeitsfahrten oder sonstige motorsportliche Veranstaltungen oder Trainingsfahrten verwendet werden. Eine Benützung zur gewerbemäßigen Beförderung ist untersagt. Zudem ist der Mieter angehalten, sämtliche eventuell zur Ein- oder Ausfuhr gelangenden Waren im Zuge eines Grenzübertrittes ordnungsgemäß zu deklarieren.`;

  const longTextColumn5 = `5) Der Mieter hat das Fahrzeug sachgemäß und pfleglich zu behandeln und die laut Betriebsanleitung vorgeschriebenen Wartungsdienste beim Vermieter bzw. einer mit diesem ausdrücklich vereinbarten anderen offiziellen Vertragswerkstätte termingerecht vornehmen zu lassen. Die dafür anfallenden Kosten sowie Reparaturkosten am Mietfahrzeug trägt der Vermieter. Sollten am Fahrzeug irgendwelche größeren Mängel auftreten, ist die Fuhrparkverwaltung des Vermieters hiervon unverzüglich zu verständigen. Reparaturen darf der Mieter nur nach ausdrücklicher Zustimmung des Vermieters durchführen lassen. Die Wahl der Reparaturwerkstätte steht in jedem Fall dem Vermieter zu.`;
  const longTextColumn6 = `6) Machen dritte Personen Ansprüche auf das Fahrzeug geltend, ist der Mieter verpflichtet, das Mietfahrzeug als Eigentum des Vermieters zu deklarieren und diesem als Wahrung der Eigentumsrechte sofort Nachricht zu geben.`;
  const longTextColumn7 = `7) Der Mieter hat an dem im Eigentum des Vermieters stehenden Kraftfahrzeug kein Zurückbehaltungsrecht gegenüber dem Vermieter.`;
  const longTextColumn8 = `8) Der Mieter haftet vom Zeitpunkt der Übernahme bis zur Rückgabe des Fahrzeuges dem Vermieter gegenüber für sämtliche Schäden infolge vorsätzlicher oder grob fahrlässiger Schadensverursachung, alkoholbedingter Fahruntüchtigkeit des Fahrzeuglenkers sowie alle durch das Ladegut entstandenen Schäden.`;
  const longTextColumn9 = `9) Der Vermieter haftet für keinerlei Schäden, die dem Mieter oder Dritten im Zusammenhang mit der Überlassung des Fahrzeuges entstehen, es sei denn, der Vermieter handelt vorsätzlich oder grob fahrlässig. Der Mieter wird den Vermieter von Ansprüchen Dritter aufgrund von Unfällen freistellen, soweit und solange nicht die Haftpflichtversicherung des Vermieters für den Schaden eintritt. Fälle, in denen die Versicherung zwar einen Schaden regulieren muss, jedoch aufgrund gesetzlicher Bestimmungen gegen den Mieter oder seinen Fahrer Rückgriff nehmen kann, berühren den Vermieter nicht. Der Mieter stellt den Vermieter von sämtlichen Ansprüchen aus der Verletzung von Gesetzen, Verordnungen oder sonstigen Vorschriften im Zusammenhang mit dem Gebrauch des Fahrzeuges durch ihn frei. Der Vermieter ist berechtigt, bei Inanspruchnahme Zahlungen zu leisten und beim Mieter Rückgriff zu nehmen.`;
  const longTextColumn10 = `10) Als Miete gelten die vertraglich festgelegten Beträge. Sämtliche Treibstoffkosten (inkl. MwSt.) gehen in jedem Fall zu Lasten des Mieters. Die Zahlung der vollen Miete hat auch dann zu erfolgen, wenn das Fahrzeug nicht den vollen Tag im Besitz des Mieters war. Die Zahlung für die gesamte Mietdauer hat anlässlich der Rückstellung des Fahrzeuges zu erfolgen. Der Auftraggeber erteilt ausdrücklich seine Zustimmung, dass im Falle exekutiver gerichtlicher Geltendmachung eines ausständigen Zahlungsbetrages, durch den Vermieter die Kundendaten an die Warenkreditevidenz des Kreditschutzverbandes 1870 übermittelt werden.`;
  const longTextColumn11 = `11) Im Übrigen gelten die einschlägigen Vorschriften des Allgemeinen Bürgerlichen Gesetzbuches. Erfüllungsort und Gerichtsstand für sämtliche Ansprüche aus diesem Vertrag oder im Zusammenhang damit ist Klagenfurt.`;



  const yPositions = [
    710, // Для первой колонки
    540, // Для второй колонки
    405, // Для третьей колонки
    135, // Для четвёртой колонки
    575, // Для пятой колонки
    530,  // Для шестой колонки
    495,   // Для седьмой колонки (при необходимости)
    435,
    265,
    150
  ];





  // Split text into lines based on text width and font size
  const textLinesColumn1 = splitTextIntoLines(longTextColumn1, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn2 = splitTextIntoLines(longTextColumn2, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn3 = splitTextIntoLines(longTextColumn3, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn33 = splitTextWithList(longTextColumn33, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn4 = splitTextIntoLines(longTextColumn4, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn5 = splitTextIntoLines(longTextColumn5, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn6 = splitTextIntoLines(longTextColumn6, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn7 = splitTextIntoLines(longTextColumn7, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn8 = splitTextIntoLines(longTextColumn8, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn9 = splitTextIntoLines(longTextColumn9, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn10 = splitTextIntoLines(longTextColumn10, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed
  const textLinesColumn11 = splitTextIntoLines(longTextColumn11, font, 8, calculateColumnWidth(page)); // Adjust maxWidth as needed

  // Calculate the starting y position for the first column (adjust as needed)
  const lineHeight = 9; // Adjust line height as needed


  drawJustifiedText(page, textLinesColumn1, font, fontSize, xColumn1, yPositions[0], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn2, font, fontSize, xColumn1, yPositions[1], columnWidth, lineHeight);

  drawJustifiedText(page, textLinesColumn3, font, fontSize, xColumn1, yPositions[2], columnWidth, lineHeight);
  drawTextWithList(page, textLinesColumn33, font, fontSize, 40, 305, lineHeight);
  drawJustifiedText(page, textLinesColumn4, font, fontSize, xColumn1, yPositions[3], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn5, font, fontSize, xColumn2, yPositions[0], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn6, font, fontSize, xColumn2, yPositions[4], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn7, font, fontSize, xColumn2, yPositions[5], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn8, font, fontSize, xColumn2, yPositions[6], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn9, font, fontSize, xColumn2, yPositions[7], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn10, font, fontSize, xColumn2, yPositions[8], columnWidth, lineHeight);
  drawJustifiedText(page, textLinesColumn11, font, fontSize, xColumn2, yPositions[9], columnWidth, lineHeight);

  // // Draw each line of text in the first column
  // textLinesColumn1.forEach((line) => {
  //   page.drawText(line, {
  //     x: 50, // Adjust the x position as needed
  //     y: y,
  //     size: 9,
  //     font: font,
  //     color: rgb(0, 0, 0),
  //   });
  //   y -= lineHeight; // Move to the next line upwards
  // });

  // // Calculate the starting y position for the second column (adjust as needed)
  // y = page.getHeight() - 50; // Reset y position to top
  // // Draw each line of text in the second column
  // textLinesColumn2.forEach((line) => {
  //   page.drawText(line, {
  //     x: page.getWidth() / 2 + 20, // Adjust x position for second column
  //     y: y,
  //     size: 9, // Adjust font size if needed
  //     font: font,
  //     color: rgb(0, 0, 0), // Adjust color if needed
  //   });
  //   y -= lineHeight; // Move to the next line upwards
  // });


  // page.drawText(`www.megabike.at`, {
  //   x: 250,
  //   y: page.getHeight() - 825, // Позиция текста
  //   size: 9,
  //   font: font, // Встроенный шрифт
  //   color: rgb(0, 0, 0), // Цвет текста
  // });

  // page.drawText(`Seite 2/2`, {
  //   x: 530,
  //   y: page.getHeight() - 825, // Позиция текста
  //   size: 9,
  //   font: font, // Встроенный шрифт
  //   color: rgb(0, 0, 0), // Цвет текста
  // });

  page.drawText('Unterschrift Kunde', {
    x: 307,
    y: page.getHeight() - 788, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });
  page.drawLine({
    start: { x: 305, y: page.getHeight() - 780 },
    end: { x: 555, y: page.getHeight() - 780 },
    thickness: 0.1, // Adjust line thickness as needed
    color: rgb(0, 0, 0), // Line color
  });

  page.drawText(`Buchungs-ID: ${orderData.id} | Seite 2/2`, {
    x: 453,
    y: page.getHeight() - 800, // Позиция текста
    size: 8,
    font: font, // Встроенный шрифт
    color: rgb(0, 0, 0), // Цвет текста
  });




};





const addThirdPageContent = async (pdfDoc, page, orderData) => {

  // Embed a standard font
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  page.drawRectangle({
    x: 400,
    y: page.getHeight() - 50,
    width: 110,
    height: 33,
    borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    borderWidth: 0.01, // Set border thickness
  });

  page.drawText('Kennzeichen', {
    x: 405,
    y: page.getHeight() - 47, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  {
    orderData.statusMotorcycle1 && (
      page.drawText(orderData.motorcycle1.kennzeichen, {
        x: 405,
        y: page.getHeight() - 35, // Adjust y position to be inside the border
        size: 14,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
      })
    )
  }

  {
    orderData.statusMotorcycle2 && (
      page.drawText(orderData.motorcycle2.kennzeichen, {
        x: 405,
        y: page.getHeight() - 35, // Adjust y position to be inside the border
        size: 14,
        font: font, // Use the embedded font
        color: rgb(0, 0, 0), // Use the gray color for the text
      })
    )
  }


  page.drawText('Motorrad Ubernahme', {
    x: 40,
    y: page.getHeight() - 35, // Позиция текста
    size: 12,
    font: fontBold, // Встроенный шрифт
    color: rgb(0, 0, 0), // Цвет текста
  });

  page.drawText('Bezeichen Sie durch einen Pfeil den Punkt sichtbarer Mängel', {
    x: 40,
    y: page.getHeight() - 50, // Позиция текста
    size: 9,
    font: font, // Встроенный шрифт
    color: rgb(0, 0, 0), // Цвет текста
  });




  const imageResponse1 = await fetch(imageUrl1);
  const imageBytes1 = await imageResponse1.arrayBuffer();

  // Embed the image in the PDF
  const image1 = await pdfDoc.embedPng(imageBytes1);

  // Draw the image on the PDF page
  page.drawImage(image1, {
    x: 330,
    y: 590,
    width: 230,
    height: 200,
  });




  page.drawRectangle({
    x: 40,
    y: page.getHeight() - 300,
    width: 180,
    height: 33,
    borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    borderWidth: 0.01, // Set border thickness
  });

  page.drawText('Ort/Datum der Übernahme', {
    x: 35,
    y: page.getHeight() - 297, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0.7, 0.7, 0.7), // Use the gray color for the text
  });



  page.drawRectangle({
    x: 220,
    y: page.getHeight() - 300,
    width: 120,
    height: 33,
    borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    borderWidth: 0.01, // Set border thickness
  });

  page.drawText('Uhrzeit', {
    x: 225,
    y: page.getHeight() - 297, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0.7, 0.7, 0.7), // Use the gray color for the text
  });

  page.drawRectangle({
    x: 350,
    y: page.getHeight() - 300,
    width: 120,
    height: 33,
    borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    borderWidth: 0.01, // Set border thickness
  });

  page.drawText('KM-Stand', {
    x: 355,
    y: page.getHeight() - 297, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0.7, 0.7, 0.7), // Use the gray color for the text
  });



  page.drawText('Tank Füllstand', {
    x: 475,
    y: page.getHeight() - 274, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  const imageResponse2 = await fetch(imageUrl2);
  const imageBytes2 = await imageResponse2.arrayBuffer();

  // Embed the image in the PDF
  const image2 = await pdfDoc.embedPng(imageBytes2);

  // Draw the image on the PDF page
  page.drawImage(image2, {
    x: 475,
    y: page.getHeight() - 300,
    width: 20,
    height: 20,
  });

  page.drawText('leer', {
    x: 479,
    y: page.getHeight() - 292, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });

  page.drawImage(image2, {
    x: 496,
    y: page.getHeight() - 300,
    width: 20,
    height: 20,
  });

  page.drawText('1/4', {
    x: 501,
    y: page.getHeight() - 292, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawImage(image2, {
    x: 517,
    y: page.getHeight() - 300,
    width: 20,
    height: 20,
  });

  page.drawText('1/2', {
    x: 522,
    y: page.getHeight() - 292, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawImage(image2, {
    x: 538,
    y: page.getHeight() - 300,
    width: 20,
    height: 20,
  });

  page.drawText('3/4', {
    x: 543,
    y: page.getHeight() - 292, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawImage(image2, {
    x: 560,
    y: page.getHeight() - 300,
    width: 20,
    height: 20,
  });

  page.drawText('voll', {
    x: 564,
    y: page.getHeight() - 292, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });



  page.drawText('Unterschrift Vertreter Händler', {
    x: 40,
    y: page.getHeight() - 348, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawLine({
    start: { x: 40, y: page.getHeight() - 350 },
    end: { x: 295, y: page.getHeight() - 350 },
    thickness: 0.1, // Adjust line thickness as needed
    color: rgb(0, 0, 0), // Line color
  });

  page.drawText('Unterschrift Benutzer', {
    x: 305,
    y: page.getHeight() - 348, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });

  page.drawLine({
    start: { x: 305, y: page.getHeight() - 350 },
    end: { x: 555, y: page.getHeight() - 350 },
    thickness: 0.1, // Adjust line thickness as needed
    color: rgb(0, 0, 0), // Line color
  });

  // page.drawText(`Buchung ID: ${orderData.id}`, {
  //   x: 30,
  //   y: page.getHeight() - 380, // Позиция текста
  //   size: 8,
  //   font: font, // Встроенный шрифт
  //   color: rgb(0, 0, 0), // Цвет текста
  // });


  page.drawLine({
    start: { x: 40, y: page.getHeight() - 390 },
    end: { x: 555, y: page.getHeight() - 390 },
    thickness: 1.1, // Adjust line thickness as needed
    color: rgb(0, 0, 0), // Line color
  });














  // page.drawRectangle({
  //   x: 400,
  //   y: page.getHeight() - 460,
  //   width: 110,
  //   height: 33,
  //   borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
  //   borderWidth: 0.01, // Set border thickness
  // });

  // page.drawText('Kennzeichen', {
  //   x: 405,
  //   y: page.getHeight() - 457, // Adjust y position to be inside the border
  //   size: 8,
  //   font: font, // Use the embedded font
  //   color: rgb(0, 0, 0), // Use the gray color for the text
  // });


  // {
  //   orderData.statusMotorcycle1 && (
  //     page.drawText(orderData.motorcycle1.kennzeichen, {
  //       x: 405,
  //       y: page.getHeight() - 445, // Adjust y position to be inside the border
  //       size: 14,
  //       font: font, // Use the embedded font
  //       color: rgb(0, 0, 0), // Use the gray color for the text
  //     })
  //   )
  // }

  // {
  //   orderData.statusMotorcycle2 && (
  //     page.drawText(orderData.motorcycle2.kennzeichen, {
  //       x: 405,
  //       y: page.getHeight() - 445, // Adjust y position to be inside the border
  //       size: 14,
  //       font: font, // Use the embedded font
  //       color: rgb(0, 0, 0), // Use the gray color for the text
  //     })
  //   )
  // }


  page.drawText('Motorrad Rückgabe', {
    x: 40,
    y: page.getHeight() - 445, // Позиция текста
    size: 12,
    font: fontBold, // Встроенный шрифт
    color: rgb(0, 0, 0), // Цвет текста
  });

  page.drawText('Bezeichen Sie durch einen Pfeil den Punkt sichtbarer Mängel', {
    x: 40,
    y: page.getHeight() - 460, // Позиция текста
    size: 9,
    font: font, // Встроенный шрифт
    color: rgb(0, 0, 0), // Цвет текста
  });




  // Draw the image on the PDF page
  page.drawImage(image1, {
    x: 330,
    y: 180,
    width: 230,
    height: 200,
  });




  page.drawRectangle({
    x: 40,
    y: page.getHeight() - 710,
    width: 180,
    height: 33,
    borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    borderWidth: 0.01, // Set border thickness
  });

  page.drawText('Ort/Datum der Übernahme', {
    x: 35,
    y: page.getHeight() - 707, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0.7, 0.7, 0.7), // Use the gray color for the text
  });



  page.drawRectangle({
    x: 220,
    y: page.getHeight() - 710,
    width: 120,
    height: 33,
    borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    borderWidth: 0.01, // Set border thickness
  });

  page.drawText('Uhrzeit', {
    x: 225,
    y: page.getHeight() - 707, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0.7, 0.7, 0.7), // Use the gray color for the text
  });

  page.drawRectangle({
    x: 350,
    y: page.getHeight() - 710,
    width: 120,
    height: 33,
    borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
    borderWidth: 0.01, // Set border thickness
  });

  page.drawText('KM-Stand', {
    x: 355,
    y: page.getHeight() - 707, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0.7, 0.7, 0.7), // Use the gray color for the text
  });



  page.drawText('Tank Füllstand', {
    x: 475,
    y: page.getHeight() - 684, // Adjust y position to be inside the border
    size: 8,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });




  // Draw the image on the PDF page
  page.drawImage(image2, {
    x: 475,
    y: page.getHeight() - 710,
    width: 20,
    height: 20,
  });

  page.drawText('leer', {
    x: 479,
    y: page.getHeight() - 702, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });

  page.drawImage(image2, {
    x: 496,
    y: page.getHeight() - 710,
    width: 20,
    height: 20,
  });

  page.drawText('1/4', {
    x: 501,
    y: page.getHeight() - 702, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawImage(image2, {
    x: 517,
    y: page.getHeight() - 710,
    width: 20,
    height: 20,
  });

  page.drawText('1/2', {
    x: 522,
    y: page.getHeight() - 702, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawImage(image2, {
    x: 538,
    y: page.getHeight() - 710,
    width: 20,
    height: 20,
  });

  page.drawText('3/4', {
    x: 543,
    y: page.getHeight() - 702, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawImage(image2, {
    x: 560,
    y: page.getHeight() - 710,
    width: 20,
    height: 20,
  });

  page.drawText('voll', {
    x: 564,
    y: page.getHeight() - 702, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });



  page.drawText('Unterschrift Vertreter Händler', {
    x: 40,
    y: page.getHeight() - 758, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });


  page.drawLine({
    start: { x: 40, y: page.getHeight() - 760 },
    end: { x: 295, y: page.getHeight() - 760 },
    thickness: 0.1, // Adjust line thickness as needed
    color: rgb(0, 0, 0), // Line color
  });

  page.drawText('Unterschrift Benutzer', {
    x: 305,
    y: page.getHeight() - 758, // Adjust y position to be inside the border
    size: 7,
    font: font, // Use the embedded font
    color: rgb(0, 0, 0), // Use the gray color for the text
  });

  page.drawLine({
    start: { x: 305, y: page.getHeight() - 760 },
    end: { x: 555, y: page.getHeight() - 760 },
    thickness: 0.1, // Adjust line thickness as needed
    color: rgb(0, 0, 0), // Line color
  });


  page.drawText(`Buchung ID: ${orderData.id}`, {
    x: 40,
    y: page.getHeight() - 810, // Позиция текста
    size: 8,
    font: font, // Встроенный шрифт
    color: rgb(0, 0, 0), // Цвет текста
  });






}


// Function to calculate column width based on page size and margins
const calculateColumnWidth = (page) => {
  const margin = 92; // Adjust margin as needed
  const gapBetweenColumns = 0; // Adjust gap between columns as needed
  return (page.getWidth() - 2 * margin - gapBetweenColumns) / 2;
};

// // Function to split text into lines based on width and font size
// function splitTextIntoLines(text, font, fontSize, maxWidth) {
//   const words = text.split(' ');
//   const lines = [];
//   let currentLine = [];
//   let currentWidth = 0;

//   words.forEach(word => {
//     const wordWidth = font.widthOfTextAtSize(word, fontSize);
//     const spaceWidth = font.widthOfTextAtSize(' ', fontSize);

//     if (currentWidth + wordWidth + (currentLine.length ? spaceWidth : 0) > maxWidth) {
//       lines.push(currentLine.join(' '));
//       currentLine = [word];
//       currentWidth = wordWidth;
//     } else {
//       currentLine.push(word);
//       currentWidth += wordWidth + (currentLine.length > 1 ? spaceWidth : 0);
//     }
//   });

//   if (currentLine.length) {
//     lines.push(currentLine.join(' '));
//   }

//   return lines;
// }

function splitTextIntoLines(text, font, fontSize, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = [];
  let currentWidth = 0;

  words.forEach(word => {
    const wordWidth = font.widthOfTextAtSize(word, fontSize);
    const spaceWidth = font.widthOfTextAtSize(' ', fontSize);

    if (currentWidth + wordWidth + (currentLine.length ? spaceWidth : 0) > maxWidth) {
      lines.push(currentLine.join(' '));
      currentLine = [word];
      currentWidth = wordWidth;
    } else {
      currentLine.push(word);
      currentWidth += wordWidth + (currentLine.length > 1 ? spaceWidth : 0);
    }
  });

  // Handle the last line
  if (currentLine.length) {
    if (currentLine.length === 1) {
      lines.push(currentLine.join(' ')); // Only one word
    } else if (currentLine.length >= 5) {
      lines.push(currentLine.join(' ')); // Four or more words
    } else if (lines.length > 0) {
      // Combine the last line with the previous line if it has less than 4 words
      const lastLine = lines.pop().split(' ');
      const combinedLine = lastLine.concat(currentLine).slice(-5); // Take last 4 words if possible
      lines.push(combinedLine.join(' '));
    } else {
      lines.push(currentLine.join(' ')); // Fallback
    }
  }

  return lines;
}


function splitTextWithList(text, font, fontSize, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = [];
  let currentWidth = 0;
  let isListItem = false;

  words.forEach(word => {
    // Определение, является ли слово началом нового элемента списка
    if (word.startsWith('-')) {
      if (currentLine.length) {
        lines.push(currentLine.join(' '));
        currentLine = [];
        currentWidth = 0;
      }
      isListItem = true;
    }

    const wordWidth = font.widthOfTextAtSize(word, fontSize);
    const spaceWidth = font.widthOfTextAtSize(' ', fontSize);

    if (currentWidth + wordWidth + (currentLine.length ? spaceWidth : 0) > maxWidth) {
      lines.push(currentLine.join(' '));
      currentLine = [word];
      currentWidth = wordWidth;
      isListItem = false; // Сбрасываем состояние элемента списка для новой строки
    } else {
      currentLine.push(word);
      currentWidth += wordWidth + (currentLine.length > 1 ? spaceWidth : 0);
    }
  });

  if (currentLine.length) {
    lines.push(currentLine.join(' '));
  }

  return lines.map(line => {
    if (line.startsWith('-')) {
      return '  ' + line; // Добавляем отступ для элементов списка
    }
    return line;
  });
}

// Функция для рисования текста со списком
function drawTextWithList(page, lines, font, fontSize, x, y, lineHeight) {
  lines.forEach(line => {
    page.drawText(line, {
      x: x + (line.startsWith('  ') ? 10 : 0), // Добавляем отступ для элементов списка
      y: y,
      size: fontSize,
      font: font,
      color: rgb(0, 0, 0),
    });
    y -= lineHeight;
  });
}


// Функция для рисования полностью выровненных строк
function drawJustifiedText(page, lines, font, fontSize, x, y, maxWidth, lineHeight) {
  lines.forEach(line => {
    const words = line.split(' ');
    const totalSpaceWidth = maxWidth - font.widthOfTextAtSize(line.replace(/\s+/g, ''), fontSize);
    const spaceWidth = font.widthOfTextAtSize(' ', fontSize);
    const extraSpaceWidth = words.length > 1 ? totalSpaceWidth / (words.length - 1) : 0;

    let currentX = x;

    words.forEach((word, index) => {
      page.drawText(word, {
        x: currentX,
        y,
        size: fontSize,
        font,
        color: rgb(0, 0, 0),
      });
      currentX += font.widthOfTextAtSize(word, fontSize) + spaceWidth + extraSpaceWidth;
    });

    y -= lineHeight;
  });
}




// const PDFComponent = ({ orderData, userFullName }) => {
//   const handleGeneratePDF = () => {
//     generateInteractivePDF(orderData, userFullName);
//   };

const API_BASE_URL = 'https://admin.motorrad-mieten-kaernten.at';

const PDFComponent = ({ orderData, userFullName }) => {
  const location = useLocation();

  const handleGeneratePDF = async () => {
    try {
      const now = new Date();
      now.setHours(now.getHours() + 2);

      // Шаг 1: Создание новой записи
      const response = await fetch(`${API_BASE_URL}/items/records/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId: orderData.id,
          name: userFullName,
          docType: 'Mietvertrag',
          date: now.toISOString(),
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при создании записи');
      }

      const newRecord = await response.json();
      console.log('Запись успешно создана:', newRecord);

      const newRecordId = newRecord.id || newRecord.data?.id;
      if (!newRecordId) {
        throw new Error('ID новой записи не найден');
      }

      // Шаг 2: Проверка на существование связи
      const exists = await checkRecordExists(orderData.id, newRecordId);
      if (exists) {
        console.log('Запись уже существует для данного заказа:', newRecordId);
        return; // Выходим, если связь уже существует
      }

      // Шаг 3: Обновляем промежуточную таблицу Orders_records
      await updateOrdersRecords(orderData.id, newRecordId);
      generateInteractivePDF(orderData, userFullName, location);

      // Шаг 4: Добавляем ID в массив записей заказа
      // await updateOrderRecords(orderData.id, newRecordId);

    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const checkRecordExists = async (orderId, newRecordId) => {
    try {
      const currentRecordsResponse = await fetch(`${API_BASE_URL}/items/Orders/${orderId}?fields=records`);
      const currentRecordsData = await currentRecordsResponse.json();

      const currentRecords = Array.isArray(currentRecordsData.data.records) ? currentRecordsData.data.records : [];
      return currentRecords.includes(newRecordId);
    } catch (error) {
      console.error('Ошибка при проверке существования записи:', error);
      return false;
    }
  };

  const updateOrdersRecords = async (orderId, newRecordId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/items/Orders_records_1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Orders_id: orderId,
          records_id: newRecordId,
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при обновлении промежуточной таблицы');
      }

      const result = await response.json();
      console.log('Промежуточная таблица успешно обновлена:', result);

    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  // const updateOrderRecords = async (orderId, newRecordId) => {
  //   try {
  //     const currentRecordsResponse = await fetch(`${API_BASE_URL}/items/Orders/${orderId}?fields=records`);
  //     const currentRecordsData = await currentRecordsResponse.json();

  //     const currentRecords = Array.isArray(currentRecordsData.data.records) ? currentRecordsData.data.records : [];
  //     const updatedRecords = [...currentRecords, newRecordId];

  //     const updateResponse = await fetch(`${API_BASE_URL}/items/Orders/${orderId}`, {
  //       method: 'PATCH',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         // 'Authorization': `Bearer ${YOUR_API_TOKEN}`, // добавьте токен
  //       },
  //       body: JSON.stringify({ records: updatedRecords }),
  //     });

  //     if (!updateResponse.ok) {
  //       const errorData = await updateResponse.json();
  //       console.error('Ошибка при обновлении заказа:', errorData);
  //       throw new Error('Ошибка при обновлении заказа');
  //     }

  //     const updatedOrder = await updateResponse.json();
  //     console.log('Заказ обновлён успешно:', updatedOrder);

  //   } catch (error) {
  //     console.error('Ошибка:', error);
  //   }
  // };


  const generateInteractivePDF = async (orderData, userFullName, location) => {

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Add the first page
    const firstPage = pdfDoc.addPage([595, 842]);
    await addFirstPageContent(pdfDoc, firstPage, orderData, userFullName, location); // Pass pdfDoc to addFirstPageContent

    // Add the second page
    const secondPage = pdfDoc.addPage([595, 842]);
    await addSecondPageContent(pdfDoc, secondPage, orderData); // Pass pdfDoc to addSecondPageContent

    // const thirdPage = pdfDoc.addPage();
    // await addThirdPageContent(pdfDoc, thirdPage, orderData); // Pass pdfDoc to addSecondPageContent

    // Save the PDF to a blob
    const pdfBytes = await pdfDoc.save();

    // Optionally, you can save the PDF to a file or send it to the client
    // For example, to save the PDF to a file:
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, 'pdfWindow', 'width=800,height=600');

  };




  const addFirstPageContent = async (pdfDoc, page, orderData, userFullName, location) => {



    function formatDate(dateString) {
      const date = new Date(dateString);

      // Опции для форматирования даты
      const options = {
        weekday: 'long',  // Полное название дня недели
        day: '2-digit',   // Двузначное число дня
        month: '2-digit', // Двузначное число месяца
        year: 'numeric'   // Полное значение года
      };

      // Форматируем дату в соответствии с немецкой локалью
      return date.toLocaleDateString('de-DE', options);
    }


    function formatDate2(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0'); // Подставка нуля для дня
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, поэтому добавляем 1 и подставляем ноль
      const year = date.getFullYear();
      return `${day}.${month}.${year}`; // Правильное использование шаблонных строк
    }



    // Helper function to format time to HH:mm
    function formatTime(timeString) {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }

    function formatPrice(price, locale = 'de-DE', currency = 'EUR') {
      // Округление цены до ближайшего целого
      const roundedPrice = Math.round(price);

      // Форматирование числа в соответствии с немецкими стандартами
      const formattedNumber = roundedPrice.toLocaleString(locale);

      // Добавление символа валюты перед числом и добавление ',-' в конце
      return `€ ${formattedNumber},-`;
    }

    function formatNumberWithDots(number) {
      // Преобразуем число в строку без десятичных знаков
      const integerPart = Math.floor(number).toString();

      // Добавляем точки для тысячных разрядов, если число >= 1000
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      // Преобразуем и возвращаем форматированное число
      return formattedIntegerPart;
    }

    function getRateDescription(rate) {
      switch (rate) {
        case 'daily':
          return 'Tagesmiete(n)';
        case 'weekly':
          return 'Wochenendmiete(n)';
        case 'combi':
          return 'Sonderanfragen/Kombinationen';
        default:
          return 'Unbekannter Tarif'; // Значение по умолчанию для неизвестных тарифов
      }
    }


    const rateDescription = getRateDescription(orderData.selectedRate);

    const freeKm = formatNumberWithDots(orderData.freeKm);


    const price1 = String(formatPrice(orderData.price));
    const price2 = String(formatPrice(orderData.price2));


    const startDate = formatDate(orderData.startDate);
    const startTime = formatTime(orderData.startTime);

    const endDate = formatDate(orderData.endDate);
    const endTime = formatTime(orderData.endTime);

    const thirdDate = formatDate2(orderData.thirdDate);


    // Embed a standard font
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    // Create a form object
    const form = pdfDoc.getForm();

    // Set positions and sizes
    const margin = 50; // Margin from the left
    const margin2 = 150;
    const yPosition = page.getHeight() - 70; // Adjust y position as needed
    const hrHeight = 10; // Adjust the distance between the HR and text
    const borderPadding = 5; // Padding between text and border
    const borderWidth = 1; // Border thickness
    const text = 'Marke';
    const text2 = 'Mietzeitraum';
    const text3 = 'VIN:';
    const text4 = 'Kennzeichen';
    const text5 = 'Megabike Kärnten (HRW Motorrad GmbH) überlässt das oben angeführte Fahrzeug mit Zulassungsschein und einem Schlüssel an:';
    const text6 = 'Familienname';
    const text7 = 'Vorname';
    const text8 = 'Adresse:';
    const text9 = 'PLZ';
    const text10 = 'Ort';
    const text11 = 'Land:';
    const text12 = 'E-mail:';
    const text13 = 'Tel.:';
    const text14 = 'Führerscheinnummer';
    // const text15 = 'Ausstellungsbehörde | Datum';
    const text16 = 'Miete von';
    const text17 = 'bis';
    const text18 = 'Mietkosten';
    const text19 = 'Freikilometer';
    const text20 = '(Mehrkilometer € 0,50 pro km)';

    const textSize = 12; // Text size

    // Measure text width
    const textWidth = font.widthOfTextAtSize(text, textSize);
    const textHeight = font.heightAtSize(textSize);

    // Calculate text position
    const textX = margin;
    const textY = yPosition - hrHeight - textHeight - borderPadding * 2;


    // Draw a horizontal line (HR)
    page.drawLine({
      start: { x: 40, y: 740 },
      end: { x: 555, y: 740 },
      thickness: 0.1, // Adjust line thickness as needed
      color: rgb(0, 0, 0), // Line color
    });

    page.drawText('Mietvertrag', {
      x: 40,
      y: page.getHeight() - 130,
      size: 23,
      font: fontBold, // Use the embedded font
      color: rgb(0, 0, 0), // Text color
    });


    page.drawText('Fahrzeugbenutzungsvertrag', {
      x: 40,
      y: page.getHeight() - 146,
      size: 13,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Text color
    });


    page.drawRectangle({
      x: 340,
      y: page.getHeight() - 570,
      width: 215,
      height: 95,
      color: rgb(0.9, 0.9, 0.9) // Gray background color
    });











    // Draw the border around the text
    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 180,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 295,
    //   y: page.getHeight() - 180,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 225,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    page.drawRectangle({
      x: 455,
      y: page.getHeight() - 230,
      width: 100,
      height: 33,
      borderColor: rgb(0.7, 0.7, 0.7), // Use light gray color for the border
      borderWidth: 0.01, // Set border thickness
    });


    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 320,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 290,
    //   y: page.getHeight() - 320,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 360,
    //   width: 470,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 400,
    //   width: 70,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 130,
    //   y: page.getHeight() - 400,
    //   width: 200,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 340,
    //   y: page.getHeight() - 400,
    //   width: 180,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 440,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 290,
    //   y: page.getHeight() - 440,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 480,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 290,
    //   y: page.getHeight() - 480,
    //   width: 230,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 550,
    //   width: 140,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 200,
    //   y: page.getHeight() - 550,
    //   width: 140,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 350,
    //   y: page.getHeight() - 550,
    //   width: 80,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    // page.drawRectangle({
    //   x: 440,
    //   y: page.getHeight() - 550,
    //   width: 80,
    //   height: 30,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });











    // Draw the text inside the border
    // page.drawText(text, {
    //   x: 52,
    //   y: page.getHeight() - 178, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text2, {
    //   x: 297,
    //   y: page.getHeight() - 178, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });



    page.drawText(text3, {
      x: 40,
      y: page.getHeight() - 205, // Adjust y position to be inside the border
      size: 14,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });



    page.drawText(text4, {
      x: 460,
      y: page.getHeight() - 227, // Adjust y position to be inside the border
      size: 8,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });





    // page.drawText(text6, {
    //   x: 52,
    //   y: page.getHeight() - 318, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text7, {
    //   x: 292,
    //   y: page.getHeight() - 318, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text8, {
    //   x: 50,
    //   y: page.getHeight() - 368, // Adjust y position to be inside the border
    //   size: 10,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text9, {
    //   x: 52,
    //   y: page.getHeight() - 398, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text10, {
    //   x: 132,
    //   y: page.getHeight() - 398, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text11, {
    //   x: 50,
    //   y: page.getHeight() - 398, // Adjust y position to be inside the border
    //   size: 10,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text12, {
    //   x: 50,
    //   y: page.getHeight() - 458, // Adjust y position to be inside the border
    //   size: 10,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text13, {
    //   x: 50,
    //   y: page.getHeight() - 428, // Adjust y position to be inside the border
    //   size: 10,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text14, {
    //   x: 30,
    //   y: page.getHeight() - 380, // Adjust y position to be inside the border
    //   size: 10,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text15, {
    //   x: 292,
    //   y: page.getHeight() - 478, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text16, {
    //   x: 52,
    //   y: page.getHeight() - 548, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text17, {
    //   x: 202,
    //   y: page.getHeight() - 548, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    // page.drawText(text18, {
    //   x: 352,
    //   y: page.getHeight() - 548, // Adjust y position to be inside the border
    //   size: 8,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });


    // page.drawText(text20, {
    //   x: 440,
    //   y: page.getHeight() - 558, // Adjust y position to be inside the border
    //   size: 7,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });
















    {
      orderData.statusMotorcycle1 && (
        page.drawText(`${orderData.motorcycle1.marke} ${orderData.motorcycle1.modell}`, {
          x: 40,
          y: page.getHeight() - 185, // Adjust y position to be inside the border
          size: 14,
          font: fontBold, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    }

    // {
    //   orderData.statusMotorcycle1 && (
    //     page.drawText(orderData.motorcycle1.modell, {
    //       x: 350,
    //       y: page.getHeight() - 160, // Adjust y position to be inside the border
    //       size: 14,
    //       font: font, // Use the embedded font
    //       color: rgb(0, 0, 0), // Use the gray color for the text
    //     })
    //   )
    // }

    {
      orderData.statusMotorcycle1 && (
        page.drawText(orderData.motorcycle1.fin, {
          x: 70,
          y: page.getHeight() - 205, // Adjust y position to be inside the border
          size: 14,
          font: font, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    }

    {
      orderData.statusMotorcycle1 && (
        page.drawText(orderData.motorcycle1.kennzeichen, {
          x: 460,
          y: page.getHeight() - 215, // Adjust y position to be inside the border
          size: 14,
          font: font, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    }



    {
      orderData.statusMotorcycle2 && (
        page.drawText(`${orderData.motorcycle2.marke} ${orderData.motorcycle2.modell}`, {
          x: 40,
          y: page.getHeight() - 185, // Adjust y position to be inside the border
          size: 14,
          font: fontBold, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    }

    // {
    //   orderData.statusMotorcycle2 && (
    //     page.drawText(orderData.motorcycle2.modell, {
    //       x: 350,
    //       y: page.getHeight() - 170, // Adjust y position to be inside the border
    //       size: 14,
    //       font: font, // Use the embedded font
    //       color: rgb(0, 0, 0), // Use the gray color for the text
    //     })
    //   )
    // }

    {
      orderData.statusMotorcycle2 && (
        page.drawText(orderData.motorcycle2.fin, {
          x: 70,
          y: page.getHeight() - 205, // Adjust y position to be inside the border
          size: 14,
          font: font, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    }

    {
      orderData.statusMotorcycle2 && (
        page.drawText(orderData.motorcycle2.kennzeichen, {
          x: 460,
          y: page.getHeight() - 215, // Adjust y position to be inside the border
          size: 14,
          font: font, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    }





    page.drawText('Das angeführte Fahrzeug verfügt über ein elektronisches, finanzamttaugliches Fahrtenbuch.', {
      x: 40,
      y: page.getHeight() - 225, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText('Sämtliche Fahrten werden mittels GPS ermittelt.', {
      x: 40,
      y: page.getHeight() - 235, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawLine({
      start: { x: 40, y: page.getHeight() - 250 },
      end: { x: 555, y: page.getHeight() - 250 },
      thickness: 0.1, // Adjust line thickness as needed
      color: rgb(0, 0, 0), // Line color
    });

    page.drawText(`Persönliche Daten`, {
      x: 40,
      y: page.getHeight() - 275, // Adjust y position to be inside the border
      size: 13,
      font: fontBold, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawText(`Megabike Kärnten (HRW Motorrad GmbH) überlässt das oben angeführte`, {
      x: 40,
      y: page.getHeight() - 295, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(`Fahrzeug mit Zulassungsschein und einem Schlüssel an:`, {
      x: 40,
      y: page.getHeight() - 305, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });








    page.drawText(`${orderData.firstName} ${orderData.lastName}`, {
      x: 45,
      y: page.getHeight() - 325, // Adjust y position to be inside the border
      size: 9,
      font: fontBold, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });





    page.drawText(`${orderData.street} ${orderData.houseNumber}`, {
      x: 45,
      y: page.getHeight() - 337, // Adjust y position as needed
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use gray color for the text
    });


    page.drawText(`${orderData.zipCode} ${orderData.city}`, {
      x: 45,
      y: page.getHeight() - 349, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    // page.drawText(orderData.city, {
    //   x: 300,
    //   y: page.getHeight() - 368, // Adjust y position to be inside the border
    //   size: 14,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    page.drawText(orderData.country, {
      x: 45,
      y: page.getHeight() - 361, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(orderData.email, {
      x: 45,
      y: page.getHeight() - 383, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(orderData.phoneNumber, {
      x: 45,
      y: page.getHeight() - 395, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });



    page.drawText(text14, {
      x: 300,
      y: page.getHeight() - 325, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(orderData.documentNumber, {
      x: 405,
      y: page.getHeight() - 325, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(`Ausstellungsbehörde`, {
      x: 300,
      y: page.getHeight() - 337, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(orderData.itionalInfo, {
      x: 405,
      y: page.getHeight() - 337, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawText(`Ausstellungsdatum`, {
      x: 300,
      y: page.getHeight() - 349, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawText(thirdDate, {
      x: 405,
      y: page.getHeight() - 349, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawText(`Ausweis-Art`, {
      x: 300,
      y: page.getHeight() - 371, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawText(orderData.documentType, {
      x: 405,
      y: page.getHeight() - 371, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawText(`Ausweis-Nummer`, {
      x: 300,
      y: page.getHeight() - 383, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawText(orderData.documentNumber, {
      x: 405,
      y: page.getHeight() - 383, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(`Ausstellungsbehörde`, {
      x: 300,
      y: page.getHeight() - 395, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(orderData.ausweisB, {
      x: 405,
      y: page.getHeight() - 395, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });





    page.drawLine({
      start: { x: 40, y: page.getHeight() - 410 },
      end: { x: 555, y: page.getHeight() - 410 },
      thickness: 0.1, // Adjust line thickness as needed
      color: rgb(0, 0, 0), // Line color
    });







    page.drawText(text2, {
      x: 40,
      y: page.getHeight() - 435, // Adjust y position to be inside the border
      size: 13,
      font: fontBold, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });



    page.drawText(`${rateDescription} / ${String(orderData.days)} Tag(e)`, {
      x: 45,
      y: page.getHeight() - 450, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });




    // page.drawText(`${String(orderData.days)} Tag(e)`, {
    //   x: 180,
    //   y: page.getHeight() - 510, // Adjust y position to be inside the border
    //   size: 14,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    page.drawText(`${startDate} - ${startTime} Uhr bis ${endDate} - ${endTime} Uhr`, {
      x: 45,
      y: page.getHeight() - 460, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    // page.drawText(`${endDate} / ${endTime}`, {
    //   x: 210,
    //   y: page.getHeight() - 538, // Adjust y position to be inside the border
    //   size: 11,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });


    page.drawLine({
      start: { x: 40, y: page.getHeight() - 475 },
      end: { x: 555, y: page.getHeight() - 475 },
      thickness: 0.1, // Adjust line thickness as needed
      color: rgb(0, 0, 0), // Line color
    });


    page.drawText(text18, {
      x: 40,
      y: page.getHeight() - 500, // Adjust y position to be inside the border
      size: 13,
      font: fontBold, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    if (orderData.statusMotorcycle1) {

      let rate;
      let rate2 = orderData.rate1;

      if (orderData.selectedRate === 'daily') {
        rate = orderData.motorcycle1.daily_rate;
      } else if (orderData.selectedRate === 'weekly') {
        rate = orderData.motorcycle1.weekend;
      } else if (orderData.selectedRate === 'combi')
        rate = orderData.motorcycle1.combi;


      if (rateDescription === 'Sonderanfragen/Kombinationen') {
        page.drawText(`${String(orderData.days)} Tag(e):`, {
          x: 45,
          y: page.getHeight() - 520, // Adjust y position to be inside the border
          size: 9,
          font: font, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        });


        if (location.pathname.startsWith("/motorcycle")) {
          page.drawText(`${String(orderData.combiDays)}x Tagesmitte | ${rate2},- / ${String(orderData.weeklyPeriods)}x Wochenendmitte | ${orderData.wochenend},-`, {
            x: 45,
            y: page.getHeight() - 530, // Adjust y position to be inside the border
            size: 9,
            font: font, // Use the embedded font
            color: rgb(0, 0, 0), // Use the gray color for the text
          });
        } else {
          page.drawText(`${String(orderData.combiDays)}x Tagesmitte | ${orderData.motorcycle1.daily_rate},- / ${String(orderData.weeklyPeriods)}x Wochenendmitte | ${orderData.motorcycle1.weekend},-`, {
            x: 45,
            y: page.getHeight() - 530, // Adjust y position to be inside the border
            size: 9,
            font: font, // Use the embedded font
            color: rgb(0, 0, 0), // Use the gray color for the text
          });
        }








      } else {
        if (location.pathname.startsWith("/motorcycle")) {
          page.drawText(`${String(orderData.days)} Tag(e) ${orderData.selectedRate === 'weekly' ? `: ${rate2},-` : `à ${rate2},- pro Tag`} (${orderData.freeKmDefault} km ${orderData.selectedRate === 'weekly' ? '' : 'pro Tag '}inklusive)`, {
            x: 45,
            y: page.getHeight() - 520, // Adjust y position to be inside the border
            size: 9,
            font: font, // Use the embedded font
            color: rgb(0, 0, 0), // Use the gray color for the text
          });
        } else {
          page.drawText(`${String(orderData.days)} Tag(e) ${orderData.selectedRate === 'weekly' ? `: ${rate},-` : `à ${rate},- pro Tag`} (${orderData.freeKmDefault} km ${orderData.selectedRate === 'weekly' ? '' : 'pro Tag '}inklusive)`, {
            x: 45,
            y: page.getHeight() - 520, // Adjust y position to be inside the border
            size: 9,
            font: font, // Use the embedded font
            color: rgb(0, 0, 0), // Use the gray color for the text
          });
        }

      }


    }




    if (orderData.statusMotorcycle2) {

      let rate;
      let rate2 = orderData.rate1;


      if (orderData.selectedRate === 'daily') {
        rate = orderData.motorcycle2.daily_rate;
      } else if (orderData.selectedRate === 'weekly') {
        rate = orderData.motorcycle2.weekend;
      } else if (orderData.selectedRate === 'combi')
        rate = orderData.motorcycle2.combi;

      if (rateDescription === 'Sonderanfragen/Kombinationen') {
        page.drawText(`${String(orderData.days)} Tag(e):`, {
          x: 45,
          y: page.getHeight() - 520, // Adjust y position to be inside the border
          size: 9,
          font: font, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        });
        page.drawText(`${String(orderData.combiDays)}x Tagesmitte | ${orderData.motorcycle2.daily_rate},- / ${String(orderData.weeklyPeriods)}x Wochenendmitte | ${orderData.motorcycle2.weekend},-`, {
          x: 45,
          y: page.getHeight() - 530, // Adjust y position to be inside the border
          size: 9,
          font: font, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        });

      } else {
        if (location.pathname.startsWith("/motorcycle")) {
          page.drawText(`${String(orderData.days)} Tag(e) ${orderData.selectedRate === 'weekly' ? `: ${rate2},-` : `à ${rate2},- pro Tag`} (${orderData.freeKmDefault} km ${orderData.selectedRate === 'weekly' ? '' : 'pro Tag '}inklusive)`, {
            x: 45,
            y: page.getHeight() - 520, // Adjust y position to be inside the border
            size: 9,
            font: font, // Use the embedded font
            color: rgb(0, 0, 0), // Use the gray color for the text
          });
        } else {
          page.drawText(`${String(orderData.days)} Tag(e) ${orderData.selectedRate === 'weekly' ? `: ${rate},-` : `à ${rate},- pro Tag`} (${orderData.freeKmDefault} km ${orderData.selectedRate === 'weekly' ? '' : 'pro Tag '}inklusive)`, {
            x: 45,
            y: page.getHeight() - 520, // Adjust y position to be inside the border
            size: 9,
            font: font, // Use the embedded font
            color: rgb(0, 0, 0), // Use the gray color for the text
          });
        }
      }
    }






    page.drawText(`Gesamtpreis: `, {
      x: 360,
      y: page.getHeight() - 500, // Adjust y position to be inside the border
      size: 13,
      font: fontBold, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });



    {
      orderData.statusMotorcycle1 && (
        page.drawText(price1, {
          x: 450,
          y: page.getHeight() - 500, // Adjust y position to be inside the border
          size: 13,
          font: fontBold, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    };

    {
      orderData.statusMotorcycle2 && (
        page.drawText(price2, {
          x: 450,
          y: page.getHeight() - 500, // Adjust y position to be inside the border
          size: 13,
          font: fontBold, // Use the embedded font
          color: rgb(0, 0, 0), // Use the gray color for the text
        })
      )
    };


    page.drawText(`${text19}: ${freeKm} km`, {
      x: 360,
      y: page.getHeight() - 520, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawText(text20, {
      x: 360,
      y: page.getHeight() - 530, // Adjust y position to be inside the border
      size: 9,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    // page.drawText(freeKm, {
    //   x: 445,
    //   y: page.getHeight() - 638, // Adjust y position to be inside the border
    //   size: 14,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Use the gray color for the text
    // });

    page.drawText(`Kaution € 500,-`, {
      x: 360,
      y: page.getHeight() - 550, // Adjust y position to be inside the border
      size: 10,
      font: fontBold, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    const checkbox1 = form.createCheckBox('checkbox1');
    checkbox1.addToPage(page, {
      x: 440,
      y: page.getHeight() - 550,
      width: 9,
      height: 9,
    });
    page.drawText('Bar', {
      x: 452,
      y: page.getHeight() - 550,
      size: 10,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Text color
    });

    const checkbox2 = form.createCheckBox('checkbox2');
    checkbox2.addToPage(page, {
      x: 480,
      y: page.getHeight() - 550,
      width: 9,
      height: 9,
    });
    page.drawText('Kreditkarte', {
      x: 492,
      y: page.getHeight() - 550,
      size: 10,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Text color
    });



    // // Create a text field
    // const textField = form.createTextField('myTextField');

    // // Add text field to the page with default appearance properties
    // textField.addToPage(page, {
    //   x: 400,
    //   y: page.getHeight() - 480,
    //   width: 120,
    //   height: 30.4,
    //   textColor: rgb(0, 0, 0), // Setting text color
    //   borderColor: rgb(0, 0, 0), // Setting border color
    //   borderWidth: 1,
    //   padding: 5,
    //   da: '/Helv 14 Tf 0 g', // Default appearance properties
    // });

    // // Set font size (if necessary, although it might not be needed here)
    // textField.setFontSize(9);





    // page.drawRectangle({
    //   x: 50,
    //   y: page.getHeight() - 710,
    //   width: 230,
    //   height: 90,
    //   borderColor: rgb(0, 0, 0), // Use the gray color for the border
    //   borderWidth: borderWidth, // Set border thickness
    // });

    if (rateDescription === 'Sonderanfragen/Kombinationen') {
      page.drawText(`Inkludiertes Versicherungspaket:`, {
        x: 45,
        y: page.getHeight() - 560, // Позиция текста
        size: 9,
        font: fontBold, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`Haftpflicht-Versicherung (EU-weit): bis € 15.000.000,-`, {
        x: 63,
        y: page.getHeight() - 571, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`Kasko-Versicherung: nur in Österreich`, {
        x: 63,
        y: page.getHeight() - 580, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`Selbstbehalt maximal € 2.000,-`, {
        x: 63,
        y: page.getHeight() - 589, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });


      const imageResponse = await fetch(imageUrl);
      const imageBytes = await imageResponse.arrayBuffer();

      // Embed the image in the PDF
      const image = await pdfDoc.embedPng(imageBytes);
      const { width, height } = image.scale(0.015);

      // Draw the image on the PDF page
      page.drawImage(image, {
        x: 50,
        y: page.getHeight() - 562 - height,
        width: width,
        height: height,
      });

      page.drawImage(image, {
        x: 50,
        y: page.getHeight() - 571 - height,
        width: width,
        height: height,
      });

    } else {

      page.drawText(`Inkludiertes Versicherungspaket:`, {
        x: 45,
        y: page.getHeight() - 553, // Позиция текста
        size: 9,
        font: fontBold, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`Haftpflicht-Versicherung (EU-weit): bis € 15.000.000,-`, {
        x: 63,
        y: page.getHeight() - 565, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`Kasko-Versicherung: nur in Österreich`, {
        x: 63,
        y: page.getHeight() - 574, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`Selbstbehalt maximal € 2.000,-`, {
        x: 63,
        y: page.getHeight() - 583, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });


      const imageResponse = await fetch(imageUrl);
      const imageBytes = await imageResponse.arrayBuffer();

      // Embed the image in the PDF
      const image = await pdfDoc.embedPng(imageBytes);
      const { width, height } = image.scale(0.015);

      // Draw the image on the PDF page
      page.drawImage(image, {
        x: 50,
        y: page.getHeight() - 556 - height,
        width: width,
        height: height,
      });

      page.drawImage(image, {
        x: 50,
        y: page.getHeight() - 565 - height,
        width: width,
        height: height,
      });
    }



    // page.drawImage(image, {
    //   x: 55,
    //   y: page.getHeight() - 688 - height,
    //   width: width,
    //   height: height,
    // });




    // const imageResponse1 = await fetch(imageUrl1);
    // const imageBytes1 = await imageResponse1.arrayBuffer();

    // // Embed the image in the PDF
    // const image1 = await pdfDoc.embedPng(imageBytes1);

    // // Draw the image on the PDF page
    // page.drawImage(image1, {
    //   x: 50,
    //   y: 775,
    //   width: 60,
    //   height: 60,
    // });

    // const imageResponse2 = await fetch(imageUrl2);
    // const imageBytes2 = await imageResponse2.arrayBuffer();

    // // Embed the image in the PDF
    // const image2 = await pdfDoc.embedPng(imageBytes2);

    // // Draw the image on the PDF page
    // page.drawImage(image2, {
    //   x: 150,
    //   y: 775,
    //   width: 60,
    //   height: 60,
    // });


    const imageResponse3 = await fetch(imageUrl3);
    const imageBytes3 = await imageResponse3.arrayBuffer();

    // Embed the image in the PDF
    const image3 = await pdfDoc.embedPng(imageBytes3);

    // Draw the image on the PDF page
    page.drawImage(image3, {
      x: 365,
      y: 750,
      width: 190,
      height: 40,
    });

    // page.drawRectangle({
    //   x: 430,
    //   y: 750,
    //   width: 120,
    //   height: 50,
    //   borderColor: rgb(0, 0, 0), // Цвет рамки
    //   borderWidth: 1,           
    // });






    if (orderData.zusatzpaket) {
      // Рисуем прямоугольник, если zusatzpaket присутствует
      if (rateDescription === 'Sonderanfragen/Kombinationen') {
        page.drawText(`Zusatzpaket € 69,- einmalig`, {
          x: 45,
          y: page.getHeight() - 540, // Позиция текста
          size: 9,
          font: font, // Встроенный шрифт
          color: rgb(0, 0, 0), // Цвет текста
        });
      } else {
        page.drawText(`Zusatzpaket € 69,- einmalig`, {
          x: 45,
          y: page.getHeight() - 530, // Позиция текста
          size: 9,
          font: font, // Встроенный шрифт
          color: rgb(0, 0, 0), // Цвет текста
        });
      }

      // page.drawText(`Zusatzpaket € 69,- einmalig`, {
      //   x: 45,
      //   y: page.getHeight() - 530, // Позиция текста
      //   size: 9,
      //   font: font, // Встроенный шрифт
      //   color: rgb(0, 0, 0), // Цвет текста
      // });

      page.drawText(`Inklusive Zusatzpaket Kaskoversicherung:`, {
        x: 45,
        y: page.getHeight() - 605, // Позиция текста
        size: 9,
        font: fontBold, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`Inkludiert auch die Reduktion des Kasko Sebstbehaltes auf`, {
        x: 50,
        y: page.getHeight() - 617, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`max. € 1.000,00 und gewährt Kasko Versicherungsschutz`, {
        x: 50,
        y: page.getHeight() - 626, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });

      page.drawText(`auch für Kroatien, Italien, Slowenien, Schweiz und Deutschland`, {
        x: 50,
        y: page.getHeight() - 635, // Позиция текста
        size: 9,
        font: font, // Встроенный шрифт
        color: rgb(0, 0, 0), // Цвет текста
      });


    } else {
      // Рисуем текст, если zusatzpaket отсутствует
      // page.drawText(`Zusatzpaket: kein`, {
      //   x: 350,
      //   y: page.getHeight() - 670, // Позиция текста
      //   size: 14,
      //   font: font, // Встроенный шрифт
      //   color: rgb(0, 0, 0), // Цвет текста
      // });
    }

    page.drawText('Unterschrift Vertreter Händler', {
      x: 42,
      y: page.getHeight() - 748, // Adjust y position to be inside the border
      size: 7,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });


    page.drawLine({
      start: { x: 40, y: page.getHeight() - 740 },
      end: { x: 290, y: page.getHeight() - 740 },
      thickness: 0.1, // Adjust line thickness as needed
      color: rgb(0, 0, 0), // Line color
    });

    page.drawText('Unterschrift Kunde', {
      x: 307,
      y: page.getHeight() - 748, // Adjust y position to be inside the border
      size: 7,
      font: font, // Use the embedded font
      color: rgb(0, 0, 0), // Use the gray color for the text
    });

    page.drawLine({
      start: { x: 305, y: page.getHeight() - 740 },
      end: { x: 555, y: page.getHeight() - 740 },
      thickness: 0.1, // Adjust line thickness as needed
      color: rgb(0, 0, 0), // Line color
    });





    page.drawText(`Buchungs-ID: ${orderData.id} | Seite 1/2`, {
      x: 453,
      y: page.getHeight() - 760, // Позиция текста
      size: 8,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });









    page.drawLine({
      start: { x: 40, y: page.getHeight() - 770 },
      end: { x: 555, y: page.getHeight() - 770 },
      thickness: 0.1, // Adjust line thickness as needed
      color: rgb(0, 0, 0), // Line color
    });

    page.drawText(`HRW Motorrad GmbH`, {
      x: 40,
      y: page.getHeight() - 790, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });

    page.drawText(`Maria-Gailer-Straße 38a, 9500 Villach`, {
      x: 40,
      y: page.getHeight() - 800, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });


    page.drawText(`Fon +43 (0)4242 377 33`, {
      x: 195,
      y: page.getHeight() - 790, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });

    page.drawText(`E-Mail office@megabike.at`, {
      x: 195,
      y: page.getHeight() - 800, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });



    page.drawText(`UID ATU 69371324`, {
      x: 320,
      y: page.getHeight() - 790, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });

    page.drawText(`FN 428387g LG Klagenfurt`, {
      x: 320,
      y: page.getHeight() - 800, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });


    page.drawText(`IBAN AT62 1700 0001 5700 0179`, {
      x: 442,
      y: page.getHeight() - 790, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });

    page.drawText(`BIC BFKKAT2K`, {
      x: 442,
      y: page.getHeight() - 800, // Позиция текста
      size: 7.5,
      font: font, // Встроенный шрифт
      color: rgb(0, 0, 0), // Цвет текста
    });


    // page.drawText(`www.megabike.at`, {
    //   x: 250,
    //   y: page.getHeight() - 825, // Позиция текста
    //   size: 9,
    //   font: font, // Встроенный шрифт
    //   color: rgb(0, 0, 0), // Цвет текста
    // });

    // page.drawText(`Seite 1/2`, {
    //   x: 530,
    //   y: page.getHeight() - 825, // Позиция текста
    //   size: 9,
    //   font: font, // Встроенный шрифт
    //   color: rgb(0, 0, 0), // Цвет текста
    // });




    // Create checkboxes with text


    // const checkbox3 = form.createCheckBox('checkbox3');
    // checkbox3.addToPage(page, {
    //   x: 50,
    //   y: page.getHeight() - 290,
    //   width: 15,
    //   height: 15,
    // });
    // page.drawText('Checkbox 3 Text', {
    //   x: 80,
    //   y: page.getHeight() - 290,
    //   size: 12,
    //   font: font, // Use the embedded font
    //   color: rgb(0, 0, 0), // Text color
    // });

    // Set values for checkboxes
    // if (orderData.checkbox1) {
    //   checkbox1.mark();
    // }
    // if (orderData.checkbox2) {
    //   checkbox2.mark();
    // }
    // if (orderData.checkbox3) {
    //   checkbox3.mark();
    // }
  };









  return (
    <div>
      <button className='panelBtnFilterFix' onClick={handleGeneratePDF}>MIETVERTRAG</button>
    </div>
  );
};

export default PDFComponent;



