import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Router, Navigate } from 'react-router-dom';
import Motorcycles from './components/Motorcycles/Motorcycles';
import MotorcycleDetails from './components/Motorcycles/MotorcyclesDetails';
import Navbar from './components/Navbar/Navbar';
import Form from './components/Form/Form';
import Restaurants from './components/Restaurants/Restaurants';
import PDF from './components/Form/pdf'
import Login from './components/Login/Login';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
import Mietpakete from './components/Mietpakete/Mietpakete';
import Header from './components/Header/Header';
import PreFooter from './components/PreFooter/PreFooter';
import { useLocation } from 'react-router-dom';
import Orders from './components/Orders/Orders';
import { getOrders } from './services/apiService';
import { getOrdersWithMotorcycleDetails } from './services/apiService';
import Game from './components/Form/Game';
import Datenschutz from './components/Datenschutz/Datenschutz';
import AGB from './components/AGB/AGB';

function App() {

  useEffect(() => {
    const userData = sessionStorage.getItem('userData');
    if (userData) {
      document.title = 'Motorrad Mieten Kaernten | ADMIN'; // Замените на нужный вам заголовок
    } else {
      document.title = 'Motorrad Mieten Kaernten'; // Замените на нужный вам заголовок
    }
  }, []);

  const [unconfirmedOrdersCount, setUnconfirmedOrdersCount] = useState(0);
  const [countUnconfirmedOrders, setCountUnconfirmedOrders] = useState(0);


  const [orderId, setOrderId] = useState(null);
  const navigate = useNavigate();

  const [selectedMotorcycle, setSelectedMotorcycle] = useState('');
  const [username, setUsername] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [formClass, setFormClass] = useState('');
  const [form2Class, setForm2Class] = useState('');
  const location = useLocation();
  const [initialVisit, setInitialVisit] = useState(true); // Флаг для отслеживания первого посещения страницы с мотоциклами
  const [initialVisit2, setInitialVisit2] = useState(true); // Флаг для отслеживания первого посещения страницы с мотоциклами


  const handleRouteChange = (location) => {
    const { pathname } = location;
    const orderIdMatch = pathname.match(/^\/orders\/([^/]+)/);
    if (orderIdMatch) {
      const id = orderIdMatch[1];
      setOrderId(id);
      console.log('Order ID:', id);
    } else {
      setOrderId(null);
    }
  };

  useEffect(() => {
    handleRouteChange(location);
  }, [location.pathname]);


  const toggleFormClass = () => {
    setFormClass(prevClass => prevClass === 'hidden' ? '' : 'hidden');
  };


  const toggleForm2Class = () => {
    setForm2Class(prevClass => prevClass === 'hidden' ? '' : 'hidden');
  };

  useEffect(() => {

    // Проверяем текущий путь URL
    if (location.pathname.includes('/motorcycle')) {
      // Если мы на странице с мотоциклом, скрываем форму, если это первое посещение
      if (initialVisit) {
        setFormClass('hidden');
        setInitialVisit(false); // Устанавливаем флаг, что первое посещение выполнено
      }
    } else {
      // Если мы покинули страницу с мотоциклами, показываем форму
      setFormClass('');
      setInitialVisit(true); // Сбрасываем флаг первого посещения при покидании страницы с мотоциклами
    }
  }, [location.pathname, initialVisit]);

  useEffect(() => {
    // Проверяем текущий путь URL
    if (location.pathname.includes('/orders')) {
      // Если мы на странице с мотоциклом, скрываем форму, если это первое посещение
      if (initialVisit2) {
        setForm2Class('hidden');
        setInitialVisit2(false); // Устанавливаем флаг, что первое посещение выполнено
      }
    } else {
      // Если мы покинули страницу с мотоциклами, показываем форму
      setForm2Class('');
      setInitialVisit2(true); // Сбрасываем флаг первого посещения при покидании страницы с мотоциклами
    }
  }, [location.pathname, initialVisit2]);

  useEffect(() => {
    // Проверяем наличие данных пользователя в sessionStorage при загрузке приложения
    const userData = sessionStorage.getItem('userData');
    if (userData) {
      setUsername(JSON.parse(userData).data.first_name);
    }
  }, []);

  useEffect(() => {
    // Проверяем наличие данных пользователя в sessionStorage при загрузке приложения
    const userData = sessionStorage.getItem('userData');
    if (userData) {
        const parsedData = JSON.parse(userData).data;
        const fullName = parsedData.first_name + ' ' + parsedData.last_name; // Конкатенация first_name и last_name
        setUserFullName(fullName);
    }
}, []);




  const handleLogin = (userData) => {
    console.log('User logged in:', userData);
    const { first_name } = userData.data;
    console.log(first_name);
    setUsername(first_name);
    navigate('/orders/list');

  };

  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userData');
    setUsername('');
    // Redirect to login page after logout
    window.location.replace('/login');
  };

  useEffect(() => {
    // Здесь загружаем заказы и вызываем функцию countUnconfirmedOrders для подсчета неподтвержденных заказов
    const fetchOrders = async () => {
      try {
        const orders = await getOrders();
        const unconfirmedCount = orders.filter(order => !order.status).length;
        setUnconfirmedOrdersCount(unconfirmedCount);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
      }
    };

    fetchOrders();
  }, []);


  console.log({ unconfirmedOrdersCount });

  return (
    <div className="App">
      <Navbar username={username} onLogout={handleLogout} unconfirmedOrdersCount={unconfirmedOrdersCount} />
      {location.pathname !== "/login" && !location.pathname.startsWith("/motorcycle") && !location.pathname.startsWith("/orders") && !location.pathname.startsWith("/intern") && !location.pathname.startsWith("/secretgame") && location.pathname !== "/datenschutz" && location.pathname !== "/agb" && (
        <>
          <Header />
          <Mietpakete />
        </>
      )}
      <Routes>
        <Route path="/" element={<Motorcycles setSelectedMotorcycle={setSelectedMotorcycle} />} />
        <Route path="/motorcycle/:name" element={<MotorcycleDetails setSelectedMotorcycle={setSelectedMotorcycle} toggleFormClass={toggleFormClass} />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/orders/:id" />
        <Route path="/orders/list/" element={sessionStorage.getItem('userData') ? <Orders countUnconfirmedOrders={countUnconfirmedOrders} username={username} userFullName={userFullName}/> : null} />
        <Route path="/intern" element={sessionStorage.getItem('userData') ? <Motorcycles setSelectedMotorcycle={setSelectedMotorcycle} /> : <Navigate to="/login" replace />} />
        <Route path="/secretgame" element={<Game/>} />
        <Route path="/datenschutz" element={<Datenschutz/>} />
        <Route path="/agb" element={<AGB/>} />

      </Routes>
      {location.pathname !== "/login" && !location.pathname.startsWith("/motorcycle") && !location.pathname.startsWith("/secretgame") && !location.pathname.startsWith("/orders") && !location.pathname.startsWith("/intern") && location.pathname !== "/datenschutz" && location.pathname !== "/agb" && (
        <>
          <Restaurants />
          <Gallery />
        </>
      )}
      {location.pathname !== "/login" && location.pathname !== "/orders/list" && !location.pathname.startsWith("/intern") && !location.pathname.startsWith("/secretgame") && location.pathname !== "/datenschutz" && location.pathname !== "/agb" && (
        <Form orderId={orderId} selectedMotorcycle={selectedMotorcycle} setSelectedMotorcycle={setSelectedMotorcycle} formClass={formClass} setFormClass={setFormClass} form2Class={form2Class} setForm2Class={setForm2Class} toggleForm2Class={toggleForm2Class} username={username} userFullName={userFullName} />
      )}
      {location.pathname !== "/login" && !location.pathname.startsWith("/motorcycle") && !location.pathname.startsWith("/orders") && !location.pathname.startsWith("/intern") && !location.pathname.startsWith("/secretgame") && location.pathname !== "/datenschutz" && location.pathname !== "/agb" && (
        <>
          <PreFooter />
        </>
      )}
      <Footer />
    </div>
  );
}

export default App;
