import React, { useState, useEffect, useRef } from 'react';
import { getOrders, updateOrderStatus } from '../../services/apiService';
import './Orders.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { getOrdersWithMotorcycleDetails } from '../../services/apiService'
import 'moment/locale/de';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import PDFComponent from '../Form/pdf';
import PDFComponent2 from '../Form/pdf2';
import PDFComponent3 from '../Form/pdf3';
import MotorcycleAnimation from '../animation/animation';
const localizer = momentLocalizer(moment);

const Orders = ({ countUnconfirmedOrders, userFullName }) => {

    //     const [showMessage, setShowMessage] = useState(false);
    //   const timerRef = useRef(null);

    //   useEffect(() => {
    //     const handleActivity = () => {
    //       // Reset the current timer if it exists
    //       if (timerRef.current) {
    //         clearTimeout(timerRef.current);
    //       }

    //       // Set a new timer for 5 minutes
    //       timerRef.current = setTimeout(() => {
    //         setShowMessage(true);
    //       },  180000);
    //       //3000
    //       //180000
    //     };

    //     // Listen for user activity events
    //     window.addEventListener('mousemove', handleActivity);
    //     window.addEventListener('keydown', handleActivity);
    //     window.addEventListener('scroll', handleActivity);
    //     window.addEventListener('touchstart', handleActivity);
    //     window.addEventListener('touchmove', handleActivity);

    //     // Set the initial timer
    //     handleActivity();

    //     // Clear timers and listeners when unmounting the component
    //     return () => {
    //       if (timerRef.current) {
    //         clearTimeout(timerRef.current);
    //       }
    //       window.removeEventListener('mousemove', handleActivity);
    //       window.removeEventListener('keydown', handleActivity);
    //       window.removeEventListener('scroll', handleActivity);
    //       window.removeEventListener('touchstart', handleActivity);
    //       window.removeEventListener('touchmove', handleActivity);
    //     };
    //   }, []);

    //   const handleRefresh = () => {
    //     window.location.reload(); 
    //   };


    const timerRef = useRef(null);
    const [showMessage, setShowMessage] = useState(false);

    const handleActivity = () => {
        // Reset the current timer if it exists
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        // Set a new timer for 5 minutes
        timerRef.current = setTimeout(() => {
            setShowMessage(true);
        }, 300000); // 5 min
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                // User left the tab, show the message immediately
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                setShowMessage(true);
            } else if (document.visibilityState === 'visible') {
                // User returned to the tab, restart the activity timer
                if (!showMessage) {
                    handleActivity();
                }
            }
        };

        // Listen for user activity events
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('scroll', handleActivity);
        window.addEventListener('touchstart', handleActivity);
        window.addEventListener('touchmove', handleActivity);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Set the initial timer
        handleActivity();

        // Clear timers and listeners when unmounting the component
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('scroll', handleActivity);
            window.removeEventListener('touchstart', handleActivity);
            window.removeEventListener('touchmove', handleActivity);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [showMessage]);

    const handleRefresh = () => {
        window.location.reload(); // Refresh the page
    };



    const [selectedOrderForPDF, setSelectedOrderForPDF] = useState(null);

    const [fieldClass, setFieldClass] = useState('');

    const toggleFieldClass = () => {
        setFieldClass(prevClass => prevClass === 'hidden' ? '' : 'hidden');
    };

    const handleGeneratePDF = (order) => {
        setSelectedOrderForPDF(order);
    };

    const handleClosePDF = () => {
        setSelectedOrderForPDF(null);
    };
    const location = useLocation();

    const [view, setView] = useState('week'); // State for the current view of the calendar

    const firstApprove = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')).data.first_name + ' ' + JSON.parse(sessionStorage.getItem('userData')).data.last_name : '';
    const secondApprove = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')).data.first_name + ' ' + JSON.parse(sessionStorage.getItem('userData')).data.last_name : '';
    const thirdApprove = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')).data.first_name + ' ' + JSON.parse(sessionStorage.getItem('userData')).data.last_name : '';

    const [orders, setOrders] = useState([]);
    console.log('ORDER1', orders);
    // console.log('ORDER2', orders[6].motorcycle1.Color);
    const [statuses, setStatuses] = useState({});
    const [viewMode, setViewMode] = useState('table');
    const [selectedOrder, setSelectedOrder] = useState(null); // State for the selected order
    const [filteredOrders, setFilteredOrders] = useState([]); // Filtered orders

    const [updatedOrderIds, setUpdatedOrderIds] = useState([]);
    const [initialStatuses, setInitialStatuses] = useState({});

    const [statusesMotorcycle1, setStatusesMotorcycle1] = useState({});
    const [statusesMotorcycle2, setStatusesMotorcycle2] = useState({});
    const [reloadPage, setReloadPage] = useState(false); // State for reloading the page after updating statuses
    const [highlightedEvent, setHighlightedEvent] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [calendarDate, setCalendarDate] = useState(new Date());

    const [localStatusesMotorcycle1, setLocalStatusesMotorcycle1] = useState({});
    const [localStatusesMotorcycle2, setLocalStatusesMotorcycle2] = useState({});

    const [filterOptions, setFilterOptions] = useState({
        status: null,
        startDate: false,
        endDate: false,
        daily: false,
        weekly: false,
        combi: false
    });
    const [showConfirmation, setShowConfirmation] = useState(false); // State for displaying the modal window
    const [showConfirmation2, setShowConfirmation2] = useState(false);
    const [showConfirmation3, setShowConfirmation3] = useState(false);
    const [showConfirmation1email, setShowConfirmation1email] = useState(false);
    const [showConfirmation2email, setShowConfirmation2email] = useState(false);
    const [showConfirmation3email, setShowConfirmation3email] = useState(false);

    const [initialVisit3, setInitialVisit3] = useState(true); // Flag for tracking the first visit to the motorcycle page

    const [radioClicked, setRadioClicked] = useState(false);

    const [isOrderChanged, setIsOrderChanged] = useState({});

    // Function for setting changes in the order
    const setOrderChanged = (orderId, changed) => {
        setIsOrderChanged(prevState => ({
            ...prevState,
            [orderId]: changed,
        }));
    };

    // Using useEffect for logging changes
    useEffect(() => {
        console.log('isOrderChanged:', isOrderChanged);
    }, [isOrderChanged]);


    useEffect(() => {

        // Check the current URL path
        if ((location.pathname.includes('/list'))) {
            // If we are on the motorcycle page, hide the form if it is the first visit
            if (initialVisit3) {
                setFieldClass('hidden');
                setInitialVisit3(false); // Set the flag that the first visit has been made
            }
        } else {
            // If we left the motorcycle page, show the form
            setFieldClass('');
            setInitialVisit3(true); // Reset the first visit flag when leaving the motorcycle page
        }
    }, [location.pathname, initialVisit3]);

    const toggleViewMode2 = (orderId) => {
        const order = orders.find(order => order.id === orderId);
        if (order) {
            setCalendarDate(new Date(`${order.startDate}T${order.startTime}`));
            setSelectedOrderId(orderId);
            setHighlightedEvent(orderId);
            setViewMode('calendar');
        }
    };


    const localizer = momentLocalizer(moment);

    useEffect(() => {
        setLocalStatusesMotorcycle1(statusesMotorcycle1);
        setLocalStatusesMotorcycle2(statusesMotorcycle2);
    }, [statusesMotorcycle1, statusesMotorcycle2]);


    const handleToggle = (orderId) => {
        // Save the current status state before making changes
        if (!initialStatuses[orderId]) {
            setInitialStatuses(prevStatuses => ({
                ...prevStatuses,
                [orderId]: statuses[orderId]
            }));
        }
        // Make changes
        setStatuses(prevStatuses => ({
            ...prevStatuses,
            [orderId]: !prevStatuses[orderId]
        }));
    };


    const handleCheckboxChange = (filterName) => {
        setFilterOptions(prevOptions => ({
            ...prevOptions,
            [filterName]: !prevOptions[filterName]
        }));
    };

    const handleConfirmationButtonClick = (orderId) => {
        setShowConfirmation(true); // Show the confirmation modal window
        // Save orderId for subsequent status update
        setUpdatedOrderIds([orderId]);
    };

    const handleConfirmationButtonClick2 = (orderId) => {
        setShowConfirmation2(true);   
        setUpdatedOrderIds([orderId]);
    };

    const handleConfirmationButtonClick3 = (orderId) => {
        setShowConfirmation3(true); 
        setUpdatedOrderIds([orderId]);
    };

    const handleConfirmationButtonClick1email = (orderId) => {
        setShowConfirmation1email(true); 
        setUpdatedOrderIds([orderId]);
    };
    const handleConfirmationButtonClick2email = (orderId) => {
        setShowConfirmation2email(true); 
        setUpdatedOrderIds([orderId]);
    };
    const handleConfirmationButtonClick3email = (orderId) => {
        setShowConfirmation3email(true);
        setUpdatedOrderIds([orderId]);
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const fetchedOrders = await getOrders();
                setOrders(fetchedOrders);

                const initialStatuses = fetchedOrders.reduce((acc, order) => {
                    acc[order.id] = order.status;
                    return acc;
                }, {});
                setStatuses(initialStatuses);
                setInitialStatuses(initialStatuses); // Save the original statuses

                // Initialize filtered orders on page load
                setFilteredOrders(fetchedOrders);
            } catch (error) {
                console.error('Failed to fetch orders:', error);
            }
        };

        fetchOrders();
    }, []);

    useEffect(() => {
        // Example of logging changes
        console.log('statusesMotorcycle1 changed:', localStatusesMotorcycle1);
        console.log('statusesMotorcycle2 changed:', statusesMotorcycle2);

        // Additional logic for determining changes
        const findChangedKeys = (prev, current) => {
            const changedKeys = {};
            for (const key in current) {
                if (prev[key] !== current[key]) {
                    changedKeys[key] = current[key];
                }
            }
            return changedKeys;
        };

        // Determining changes in statusesMotorcycle1
        const changedStatusesMotorcycle1 = findChangedKeys(prevLocalStatusesMotorcycle1, localStatusesMotorcycle1);
        console.log('Changed statusesMotorcycle1:', changedStatusesMotorcycle1);

        // Determining changes in statusesMotorcycle2
        const changedStatusesMotorcycle2 = findChangedKeys(prevStatusesMotorcycle2, statusesMotorcycle2);
        console.log('Changed statusesMotorcycle2:', changedStatusesMotorcycle2);

        // Updating previous states
        setPrevLocalStatusesMotorcycle1(localStatusesMotorcycle1);
        setPrevStatusesMotorcycle2(statusesMotorcycle2);

    }, [localStatusesMotorcycle1, statusesMotorcycle2]);

    // Initialization of previous states
    const [prevLocalStatusesMotorcycle1, setPrevLocalStatusesMotorcycle1] = useState(localStatusesMotorcycle1);
    const [prevStatusesMotorcycle2, setPrevStatusesMotorcycle2] = useState(statusesMotorcycle2);




    useEffect(() => {
        if (orders.length > 0) {
            const initialStatusesMotorcycle1 = orders.reduce((acc, order) => {
                acc[order.id] = order.statusMotorcycle1;
                return acc;
            }, {});
            const initialStatusesMotorcycle2 = orders.reduce((acc, order) => {
                acc[order.id] = order.statusMotorcycle2;
                return acc;
            }, {});
            setStatusesMotorcycle1(initialStatusesMotorcycle1);
            setStatusesMotorcycle2(initialStatusesMotorcycle2);
        }
    }, [orders]);

    // const handleMotorcycle1StatusChange = (orderId) => {
    //     setStatusesMotorcycle1(prevStatuses => ({
    //         ...prevStatuses,
    //         [orderId]: true,
    //     }));
    //     setStatusesMotorcycle2(prevStatuses => ({
    //         ...prevStatuses,
    //         [orderId]: false,
    //     }));
    // };

    // const handleMotorcycle2StatusChange = (orderId) => {
    //     setStatusesMotorcycle1(prevStatuses => ({
    //         ...prevStatuses,
    //         [orderId]: false,
    //     }));
    //     setStatusesMotorcycle2(prevStatuses => ({
    //         ...prevStatuses,
    //         [orderId]: true,
    //     }));
    // };


    const handleMotorcycle1StatusChange = (orderId) => {
        setOrders(prevOrders => prevOrders.map(order => {
            if (order.id === orderId) {
                return {
                    ...order,
                    statusMotorcycle1: true,
                    statusMotorcycle2: false
                };
            }
            return order;
        }));
        setOrderChanged(orderId, true); // Indicate that a change has occurred in the order

    };

    const handleMotorcycle2StatusChange = (orderId) => {
        setOrders(prevOrders => prevOrders.map(order => {
            if (order.id === orderId) {
                return {
                    ...order,
                    statusMotorcycle1: false,
                    statusMotorcycle2: true
                };
            }
            return order;
        }));
        setOrderChanged(orderId, true);

    };






    // const toggleStatus = (orderId) => {
    //     setStatuses(prevStatuses => ({
    //         ...prevStatuses,
    //         [orderId]: !prevStatuses[orderId],
    //     }));
    //     // Add orderId to the array of updated orders
    //     setUpdatedOrderIds(prevIds => [...prevIds, orderId]);
    // };

    const toggleStatus = (orderId) => {
        // Get the original status value of the order
        const initialStatus = initialStatuses[orderId];

        // If the original status value of the order is true, do not allow changing its value.
        if (initialStatus === true) {
            return;
        }

        // Otherwise, allow changing the order status value.
        setStatuses(prevStatuses => ({
            ...prevStatuses,
            [orderId]: !prevStatuses[orderId],
        }));
        // Add orderId to the array of updated orders
        setUpdatedOrderIds(prevIds => [...prevIds, orderId]);
    };







    const updateOrderStatuses = async (firstApprove, firstApproveTime) => {
        if (showConfirmation) {
            setShowConfirmation(false); // Hide the confirmation modal window
            try {
                // Create an array of updated orders
                const updatedOrders = orders.filter(order => updatedOrderIds.includes(order.id))
                    .map(order => ({
                        ...order,
                        status: statuses[order.id],
                        statusMotorcycle1: statusesMotorcycle1[order.id],
                        statusMotorcycle2: statusesMotorcycle2[order.id],
                        firstApprove: firstApprove, // Pass firstApprove to the order object
                        firstApproveTime: firstApproveTime // Pass firstApproveTime to the order object
                    }));

                // Send a request to the server to update the order statuses
                await Promise.all(updatedOrders.map(order => updateOrderStatus(order.id, order)));

                // Update the order state in local state
                setOrders(prevOrders => prevOrders.map(order => {
                    if (updatedOrderIds.includes(order.id)) {
                        return {
                            ...order,
                            status: statuses[order.id],
                            statusMotorcycle1: statusesMotorcycle1[order.id],
                            statusMotorcycle2: statusesMotorcycle2[order.id],
                            firstApprove: firstApprove, 
                            firstApproveTime: firstApproveTime 
                        };
                    }
                    return order;
                }));
                setUpdatedOrderIds([]); // Reset the array of updated orders
                console.error(firstApprove);

            } catch (error) {
                console.error('Failed to update order statuses (updateOrderStatuses):', error);
            }
        } else {
            setShowConfirmation(true); 
        }
    };


    const updateOrderStatuses2 = async (secondApprove, secondApproveTime) => {
        if (showConfirmation2) {
            setShowConfirmation2(false); 
            try {
              
                const updatedOrders = orders.filter(order => updatedOrderIds.includes(order.id))
                    .map(order => ({
                        ...order,
                        status: true, 
                        secondApprove: secondApprove, 
                        secondApproveTime: secondApproveTime 
                    }));

        
                await Promise.all(updatedOrders.map(order => updateOrderStatus(order.id, order)));

            
                setOrders(prevOrders => prevOrders.map(order => {
                    if (updatedOrderIds.includes(order.id)) {
                        return {
                            ...order,
                            status: true, 
                            secondApprove: secondApprove, 
                            secondApproveTime: secondApproveTime
                        };
                    }
                    return order;
                }));
                setUpdatedOrderIds([]); 
                console.error(secondApprove);

            } catch (error) {
                console.error('Failed to update order statuses (updateOrderStatuses2):', error);
            }
        } else {
            setShowConfirmation2(true); 
        }
    };



    const cancellation = async (thirdApprove, thirdApproveTime) => {
        if (showConfirmation3) {
            setShowConfirmation3(false); 
            try {
              
                const updatedOrders = orders.filter(order => updatedOrderIds.includes(order.id))
                    .map(order => ({
                        ...order,
                        cancellation: true, 
                        thirdApprove: thirdApprove, 
                        thirdApproveTime: thirdApproveTime 
                    }));

            
                await Promise.all(updatedOrders.map(order => updateOrderStatus(order.id, order)));

          
                setOrders(prevOrders => prevOrders.map(order => {
                    if (updatedOrderIds.includes(order.id)) {
                        return {
                            ...order,
                            cancellation: true, 
                            thirdApprove: thirdApprove, 
                            thirdApproveTime: thirdApproveTime 
                        };
                    }
                    return order;
                }));
                setUpdatedOrderIds([]); 
                console.error(thirdApprove);

            } catch (error) {
                console.error('Failed to update order statuses (cancellation):', error);
            }
        } else {
            setShowConfirmation3(true); 
        }
    };



    const update1email = async () => {
        if (showConfirmation1email) {
            setShowConfirmation1email(false); 
            try {
                
                const updatedOrders = orders.filter(order => updatedOrderIds.includes(order.id))
                    .map(order => ({
                        ...order,
                        status: statuses[order.id],
                        statusMotorcycle1: statusesMotorcycle1[order.id],
                        statusMotorcycle2: statusesMotorcycle2[order.id],
                        send1email: 1,
                        rate2: selectedOrder.motorcycle2?.daily_rate,
                        wochenend2: selectedOrder.motorcycle2?.weekend,
                    }));

           
                await Promise.all(updatedOrders.map(order => updateOrderStatus(order.id, order)));


                setUpdatedOrderIds([]); 
        

            } catch (error) {
                console.error('Failed to update order statuses (updateOrderStatuses):', error);
            }
        } else {
            setShowConfirmation1email(true); 
        }
    };
    const update2email = async () => {
        if (showConfirmation2email) {
            setShowConfirmation2email(false); 
            try {
             
                const updatedOrders = orders.filter(order => updatedOrderIds.includes(order.id))
                    .map(order => ({
                        ...order,
                        status: statuses[order.id],
                        statusMotorcycle1: statusesMotorcycle1[order.id],
                        statusMotorcycle2: statusesMotorcycle2[order.id],
                        send2email: 1,
                        rate2: selectedOrder.motorcycle2?.daily_rate,
                        wochenend2: selectedOrder.motorcycle2?.weekend,
                    }));

         
                await Promise.all(updatedOrders.map(order => updateOrderStatus(order.id, order)));


                setUpdatedOrderIds([]); 
                console.error(firstApprove);

            } catch (error) {
                console.error('Failed to update order statuses (updateOrderStatuses):', error);
            }
        } else {
            setShowConfirmation2email(true);
        }
    };
    const update3email = async () => {
        if (showConfirmation3email) {
            setShowConfirmation3email(false); 
            try {
           
                const updatedOrders = orders.filter(order => updatedOrderIds.includes(order.id))
                    .map(order => ({
                        ...order,
                        status: statuses[order.id],
                        statusMotorcycle1: statusesMotorcycle1[order.id],
                        statusMotorcycle2: statusesMotorcycle2[order.id],
                        send3email: 1,
                        rate2: selectedOrder.motorcycle2?.daily_rate,
                        wochenend2: selectedOrder.motorcycle2?.weekend,
                    }));

           
                await Promise.all(updatedOrders.map(order => updateOrderStatus(order.id, order)));


                setUpdatedOrderIds([]); 
                console.error(firstApprove);

            } catch (error) {
                console.error('Failed to update order statuses (updateOrderStatuses):', error);
            }
        } else {
            setShowConfirmation3email(true); 
        }
    };




    // const updateOrderStatusForOrder = async (orderId) => {
    //     if (showConfirmation) {
    //         setShowConfirmation(false); // Скрыть модальное окно подтверждения

    //         try {
    //             // Получаем статусы для данного заказа
    //             const orderStatus = statuses[orderId];
    //             const orderStatusMotorcycle1 = statusesMotorcycle1[orderId];
    //             const orderStatusMotorcycle2 = statusesMotorcycle2[orderId];

    //             // Обновляем статусы в базе данных
    //             await updateOrderStatus(orderId, {
    //                 status: orderStatus,
    //                 statusMotorcycle1: orderStatusMotorcycle1,
    //                 statusMotorcycle2: orderStatusMotorcycle2,
    //                 firstApprove: firstApprove
    //             });

    //             // Обновляем статусы в локальном состоянии
    //             setOrders(prevOrders => prevOrders.map(order => {
    //                 if (order.id === orderId) {
    //                     return {
    //                         ...order,
    //                         status: orderStatus,
    //                         statusMotorcycle1: orderStatusMotorcycle1,
    //                         statusMotorcycle2: orderStatusMotorcycle2
    //                     };
    //                 }
    //                 return order;
    //             }));

    //             // Удаляем orderId из массива обновленных заказов
    //             setUpdatedOrderIds(prevIds => prevIds.filter(id => id !== orderId));
    //         } catch (error) {
    //             console.error('Failed to update order status:', error);
    //         }
    //     } else {
    //         setShowConfirmation(true); // Показать модальное окно подтверждения
    //     }

    // };


    const now = new Date();
    const firstApproveTime = new Date(now.getTime() + 2 * 60 * 60 * 1000); 
    const secondApproveTime = new Date(now.getTime() + 2 * 60 * 60 * 1000); 
    const thirdApproveTime = new Date(now.getTime() + 2 * 60 * 60 * 1000); 



    const handleConfirmationOk = (orderId) => {
        setShowConfirmation(false);
        updateOrderStatuses(firstApprove, firstApproveTime);
        // updateOrderStatuses2(secondApprove, secondApproveTime);


        // updateOrderStatusForOrder(orderId); 
        setReloadPage(true); 
    };

    const handleConfirmationOk2 = (orderId) => {
        setShowConfirmation2(false);
        // updateOrderStatuses(firstApprove, firstApproveTime);
        updateOrderStatuses2(secondApprove, secondApproveTime);


        // updateOrderStatusForOrder(orderId); 
        setReloadPage(true);
    };
    const handleConfirmationOk3 = (orderId) => {
        setShowConfirmation3(false);
        // updateOrderStatuses(firstApprove, firstApproveTime);
        cancellation(thirdApprove, thirdApproveTime);


        // updateOrderStatusForOrder(orderId); 
        setReloadPage(true); 
    };

    const handleConfirmationCancel = () => {
        setShowConfirmation(false); 
        setUpdatedOrderIds([]); 
        setStatuses(initialStatuses); 
        // setInitialStatuses({});
    };

    const handleConfirmationCancel2 = () => {
        setShowConfirmation2(false); 
        setUpdatedOrderIds([]); 
        setStatuses(initialStatuses); 
        // setInitialStatuses({});
    };
    const handleConfirmationCancel3 = () => {
        setShowConfirmation3(false); 
        setUpdatedOrderIds([]); 
        setStatuses(initialStatuses); 
        // setInitialStatuses({});
    };


    const handleConfirmation1emailOk = (orderId) => {
        setShowConfirmation1email(false);
        update1email();
        // updateOrderStatuses2(secondApprove, secondApproveTime);


        // updateOrderStatusForOrder(orderId); 
        setReloadPage(true); 
    };

    const handleConfirmation1emailCancel = () => {
        setShowConfirmation1email(false); 
        setUpdatedOrderIds([]); 
        setStatuses(initialStatuses); 
        // setInitialStatuses({});
    };
    const handleConfirmation2emailOk = (orderId) => {
        setShowConfirmation2email(false);
        update2email();
        // updateOrderStatuses2(secondApprove, secondApproveTime);


        // updateOrderStatusForOrder(orderId); 
        setReloadPage(true); 
    };

    const handleConfirmation2emailCancel = () => {
        setShowConfirmation2email(false); 
        setUpdatedOrderIds([]); 
        setStatuses(initialStatuses); 
        // setInitialStatuses({});
    };
    const handleConfirmation3emailOk = (orderId) => {
        setShowConfirmation3email(false);
        update3email();
        // updateOrderStatuses2(secondApprove, secondApproveTime);


        // updateOrderStatusForOrder(orderId); 
        setReloadPage(true); 
    };

    const handleConfirmation3emailCancel = () => {
        setShowConfirmation3email(false); 
        setUpdatedOrderIds([]); 
        setStatuses(initialStatuses); 
        // setInitialStatuses({});
    };

    const toggleViewMode = () => {
        setViewMode(prevMode => (prevMode === 'table' ? 'calendar' : 'table'));
    };


    const handleOrderClick = (event) => {
        const clickedOrder = orders.find(order => order.id === event.id); 
        setSelectedOrder(clickedOrder);
    };

    const formatDate = (dateString) => {
        const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return ""; 
        return date.toLocaleDateString('de-DE', options);
    };

    const formatDateOnly = (dateString) => {
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return ""; 
        return date.toLocaleDateString('de-DE', options);
    };

    const formatDateDay = (dateString) => {
        const options = { weekday: 'long' };
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return ""; 
        return date.toLocaleDateString('de-DE', options);
    };

    const formatDateMY = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return ""; 
        return date.toLocaleDateString('de-DE', options);
    };






    const formatTime = (timeString) => {
        if (!timeString) return "";
        const [hours, minutes] = timeString.split(":");
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    };

    function formatDate2(dateString) {
        const options = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };

        const date = new Date(dateString);
        let formattedDate = date.toLocaleDateString('de-DE', options);

 
        formattedDate = formattedDate.replace(' um ', '/');

        return formattedDate;
    }






    useEffect(() => {
        if (reloadPage) {
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, [reloadPage]);


    const filterOrders = () => {
        let filtered = orders;

     
        if (filterOptions.status !== null && filterOptions.status !== 'storniert') {
            filtered = filtered.filter(order => {
                if (filterOptions.status === true) {
                    return statuses[order.id] && !order.thirdApprove; 
                } else if (filterOptions.status === false) {
                    return !statuses[order.id] && !order.thirdApprove; 
                }
                return true; 
            });
        } else if (filterOptions.status === 'storniert') {
            filtered = filtered.filter(order => order.thirdApprove); 
        }


        const { startDate } = filterOptions;
        if (startDate === 'asc') {
            filtered = [...filtered].sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        } else if (startDate === 'desc') {
            filtered = [...filtered].sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        }


        const { daily, weekly, combi } = filterOptions;
        if (!daily && !weekly && !combi) {
            setFilteredOrders(filtered);
            return;
        }

        if (!daily) {
            filtered = filtered.filter(order => order.selectedRate !== 'daily');
        }
        if (!weekly) {
            filtered = filtered.filter(order => order.selectedRate !== 'weekly');
        }
        if (!combi) {
            filtered = filtered.filter(order => order.selectedRate !== 'combi');
        }

        setFilteredOrders(filtered);
    };




    const findOrdersWithSameMotorcycle = (selectedOrder, allOrders) => {
        const { selectedMotorcycle, selectedMotorcycle2, startDate } = selectedOrder;

        const matchingOrders1 = []; 
        const matchingOrders2 = []; 

        allOrders.forEach(order => {
            if (order.id === selectedOrder.id) return;

            if (order.selectedMotorcycle === selectedMotorcycle) {
                if (order.startDate === startDate) {
                    matchingOrders1.push(order);
                }
            }

            if (order.selectedMotorcycle === selectedMotorcycle2) {
                if (order.startDate === startDate) {
                    matchingOrders2.push(order);
                }
            }
        });

        return { matchingOrders1, matchingOrders2 };
    };


    const getMotorcycleColor = (order) => {
        let color = 'transparent'; // Default color
        let textColor = 'black'; // Default text color

        if (!order.statusMotorcycle1 && !order.statusMotorcycle2) {
            color = '#adadad'; // Gray if both statuses are false
        } else if (order.statusMotorcycle1) {
            color = order.motorcycle1.Color; // Color of motorcycle1 if its status is true
        } else if (order.statusMotorcycle2) {
            color = order.motorcycle2.Color; // Color of motorcycle2 if its status is true
        }

        if (order.cancellation) {
            // Set light gray background and red text for canceled orders
            color = '#ffc6c6';
            textColor = 'red';
        } else {
            // Apply opacity if color is in hex or rgb format
            if (color.startsWith('#')) {
                color = hexToRgba(color, 0.4);
            } else if (color.startsWith('rgb(')) {
                color = rgbToRgba(color, 0.4);
            } else if (color.startsWith('rgba(')) {
                color = color.replace(/[\d.]+\)$/g, '0.5)');
            }
        }

        return { backgroundColor: color, color: textColor };
    };

    //  HEX to RGBA
    const hexToRgba = (hex, alpha) => {
        if (hex.length === 4) {
            hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
        }
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    //  RGB to RGBA
    const rgbToRgba = (rgb, alpha) => {
        return rgb.replace(/^rgb\((\d+), (\d+), (\d+)\)$/, `rgba($1, $2, $3, ${alpha})`);
    };


    const getEventTitle = (order) => {
        let title = `${order.id} | `; 

        if (!order.statusMotorcycle1 && !order.statusMotorcycle2) {
            title += `${order.selectedMotorcycle} ? ${order.selectedMotorcycle2}`;
        } else if (order.statusMotorcycle1) {
            title += order.selectedMotorcycle;
        } else if (order.statusMotorcycle2) {
            title += order.selectedMotorcycle2;
        }

        title += ` / ${order.firstName}`;  

        if (order.cancellation) {
            title += `<b> !!! STORNIERT !!! </b>`; 

        }


        return title;
    };

    const handleButtonClick2 = () => {
        toggleFieldClass();
    };



    const groupedRecords = selectedOrderForPDF?.records?.reduce((acc, record) => {
        const docType = record.records_id?.docType || 'Other';
        if (!acc[docType]) {
            acc[docType] = [];
        }
        acc[docType].push(record);
        return acc;
    }, {});


    const formatDate3 = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return ""; 

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}.${month}.${year} / ${hours}:${minutes}:${seconds}`;
    };





    return (
        <div>
            <div className='panel'>

                <div className='panelContainer'>
                    <div className='viewBtnContainer'>
                        <button className="custom-btn btn-12" onClick={toggleViewMode}>
                            {viewMode === 'table' ? (
                                <>
                                    <span>Calendar</span>
                                    <span>Table</span>
                                </>
                            ) : (
                                <>
                                    <span>Table</span>
                                    <span>Calendar</span>
                                </>
                            )}
                        </button>

                        <button className='panelBtnFilter' onClick={handleButtonClick2}>FILTERS</button>
                    </div>
                    <div className={`${fieldClass}`}>

                        <div className="filter-buttons">


                            <div className='filter-buttons-container'>
                                <th className='filterFlex'>

                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                name="status"
                                                checked={filterOptions.status === null}
                                                onChange={() => setFilterOptions({ ...filterOptions, status: null })}
                                            />
                                            Alle
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="status"
                                                checked={filterOptions.status === true}
                                                onChange={() => setFilterOptions({ ...filterOptions, status: true })}
                                            />
                                            Bestätigt
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                name="status"
                                                checked={filterOptions.status === false}
                                                onChange={() => setFilterOptions({ ...filterOptions, status: false })}
                                            />
                                            Nicht bestätigt
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="status"
                                                checked={filterOptions.status === 'storniert'}
                                                onChange={() => setFilterOptions({ ...filterOptions, status: 'storniert' })}
                                            />
                                            Storniert
                                        </label>
                                    </div>


                                </th>





                                {viewMode === 'table' && (
                                    <>

                                        <th>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="startDateSort"
                                                    checked={filterOptions.startDate === 'desc'}
                                                    onChange={() => setFilterOptions({ ...filterOptions, startDate: 'desc' })}
                                                />
                                                <FontAwesomeIcon icon={faArrowDown} /> Startdatum (Spät zu Früh)
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="startDateSort"
                                                    checked={filterOptions.startDate === 'asc'}
                                                    onChange={() => setFilterOptions({ ...filterOptions, startDate: 'asc' })}
                                                />
                                                <FontAwesomeIcon icon={faArrowUp} /> Startdatum (Früh zu Spät)
                                            </label>
                                        </th>

                                    </>
                                )}


                                <th className='filterFlex'>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={filterOptions.daily}
                                                onChange={() => handleCheckboxChange('daily')}
                                            />
                                            Tagesmieten
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={filterOptions.weekly}
                                                onChange={() => handleCheckboxChange('weekly')}
                                            />
                                            Wochenendmieten
                                        </label>
                                    </div>

                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={filterOptions.combi}
                                            onChange={() => handleCheckboxChange('combi')}
                                        />
                                        Sonderanfragen/Kombinationen
                                    </label>


                                </th>
                            </div>





                            <div className='filterButtonsContainer'>
                                <button onClick={() => filterOrders()}>Apply Filters</button>
                                <button onClick={() => {
                                    setFilterOptions({
                                        status: null,
                                        startDate: false,
                                        endDate: false,
                                        daily: false,
                                        weekly: false,
                                        combi: false
                                    });
                                    setFilteredOrders(orders);
                                }}>
                                    Remove Filters
                                </button>
                            </div>




                        </div>
                    </div>

                </div>

                <button className='panelBtnFilterFix2 aktualBtn' onClick={handleRefresh}>DATEN AKTUALISIEREN</button>

                <p className='hinweis' style={{color:'red', border: '1px solid red', padding:'5px'}}>Wichtige Hinweis: In der Tabelle, wenn man auf den Name klickt, sind drei neue Tasten für das erneute Senden von drei E-Mails verfügbar!!!</p>


                {/* <button onClick={updateOrderStatuses}>Update Statuses</button> */}
                {/* <button className='btnToggle' style={{ margin: '30px 46% 6px' }}

                    onClick={toggleViewMode}>Table / Calendar View</button> */}



            </div>

            {showMessage && (
                <div>
                    <div className="modal5-overlay"></div>
                    <div className="modal5">
                        <MotorcycleAnimation />
                        <p>Sie haben diese Seite verlassen oder die Aktivitätszeit hat 5 Minuten überschritten</p>
                        <button onClick={handleRefresh}>AKTUALISIEREN</button>
                    </div>
                </div>
            )}


            {showConfirmation && (
                <div className="confirmation-modal">
                    <p>Sind Sie sicher, dass Sie den Motorrad bestätigen möchten?</p>
                    <div>
                        <button className='ja' onClick={handleConfirmationOk}>Ja</button>
                        <button className='nein' onClick={handleConfirmationCancel}>Nein</button>
                    </div>
                </div>
            )}

            {showConfirmation2 && (
                <div className="confirmation-modal">
                    <p>Sind Sie sicher, dass Sie den Bestellstatus aktualisieren möchten?</p>
                    <div>
                        <button className='ja' onClick={handleConfirmationOk2}>Ja</button>
                        <button className='nein' onClick={handleConfirmationCancel2}>Nein</button>
                    </div>
                </div>
            )}
            {showConfirmation3 && (
                <div className="confirmation-modal">
                    <h3 style={{ color: 'red' }}>!!! Achtung !!!</h3>
                    <p style={{ color: 'red' }}> Nach der Bestätigung kann der Vorgang nicht mehr rückgängig gemacht werden.</p>
                    <p style={{ color: 'red' }}> Trotzdem stornieren?</p>
                    <div>
                        <button className='ja' onClick={handleConfirmationOk3}>Ja</button>
                        <button className='nein' onClick={handleConfirmationCancel3}>Nein</button>
                    </div>
                </div>
            )}

            {showConfirmation1email && (
                <div className="confirmation-modal">
                    <p>1 email wieder senden?</p>
                    <div>
                        <button className='ja' onClick={handleConfirmation1emailOk}>Ja</button>
                        <button className='nein' onClick={handleConfirmation1emailCancel}>Nein</button>
                    </div>
                </div>
            )}
            {showConfirmation2email && (
                <div className="confirmation-modal">
                    <p>2 email wieder senden?</p>
                    <div>
                        <button className='ja' onClick={handleConfirmation2emailOk}>Ja</button>
                        <button className='nein' onClick={handleConfirmation2emailCancel}>Nein</button>
                    </div>
                </div>
            )}
            {showConfirmation3email && (
                <div className="confirmation-modal">
                    <p>3 email wieder senden?</p>
                    <div>
                        <button className='ja' onClick={handleConfirmation3emailOk}>Ja</button>
                        <button className='nein' onClick={handleConfirmation3emailCancel}>Nein</button>
                    </div>
                </div>
            )}



            {viewMode === 'table' ? (

                <div>
                    {selectedOrder && (
                        <div className="order-modal">
                            <div className="order-details">
                                <button className="close-button" onClick={() => setSelectedOrder(null)}>x</button>


                                <div className='modalInfoHead'>


                                    <div>
                                        <h2 onClick={() => toggleViewMode2(selectedOrder.id)} style={{ cursor: 'pointer' }}
                                        >Buchung № {selectedOrder.id}</h2>

                                        <p>
                                            {selectedOrder.status
                                                ? 'Beim buchung war ausgewählt:'
                                                : 'Wählen Sie zur Bestätigung ein Motorrad aus:'}
                                        </p>
                                        <p style={{ fontSize: '13px' }}>
                                            (zusatzpaket: {selectedOrder.zusatzpaket ? '€ 69,-' : 'kein'})
                                        </p>
                                    </div>



                                    <div>
                                        <div className='modalBtnContainer2'>
                                            <div className='modalButton'>
                                                {selectedOrder.firstApprove ? (
                                                    <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                        <div className='panelIcon2'>

                                                            <p style={{ color: selectedOrder.thirdApprove ? 'red' : 'green' }}><b>Motorrad Bestätigt</b>
                                                            </p>
                                                            <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: selectedOrder.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>


                                                        </div>
                                                        <p className='approve2'>{selectedOrder.firstApprove}</p>
                                                        <p className='approve2'>{formatDate2(selectedOrder.firstApproveTime)}</p>
                                                        <hr />
                                                    </div>


                                                ) : (

                                                    <div className='panelBtnCOntainer'>
                                                        <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick(selectedOrder.id)} disabled={!isOrderChanged[selectedOrder.id] || selectedOrder.status}
                                                        >Motorrad bestätigen</button>
                                                    </div>
                                                )}
                                            </div>

                                            <div className='modalButton'>
                                                {selectedOrder.secondApprove ? (
                                                    <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                        <div className='panelIcon2'>
                                                            <p style={{ color: selectedOrder.thirdApprove ? 'red' : 'green' }}><b>Auftrag fixiert</b>
                                                            </p>
                                                            <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: selectedOrder.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>

                                                        </div>
                                                        <p className='approve2'>{selectedOrder.secondApprove}</p>
                                                        <p className='approve2'>{formatDate2(selectedOrder.secondApproveTime)}</p>
                                                        <hr />
                                                    </div>


                                                ) : (
                                                    <div className='panelBtnCOntainer'>
                                                        <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick2(selectedOrder.id)} disabled={(!selectedOrder.statusMotorcycle1 && !selectedOrder.statusMotorcycle2) || (!selectedOrder.agb) || (selectedOrder.thirdApprove)}>Bestätigung senden</button>

                                                    </div>
                                                )}
                                            </div>
                                            <div className='modalButton'>
                                                {selectedOrder.thirdApprove ? (
                                                    <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                        <div className='panelIcon2'>
                                                            <p><b>Auftrag storniert </b></p>
                                                            <i class="fa fa-ban" aria-hidden="true" style={{ marginLeft: '8px', fontSize: '18px' }}></i>
                                                        </div>

                                                        <p className='approve2'>{selectedOrder.thirdApprove}</p>
                                                        <p className='approve2'>{formatDate2(selectedOrder.thirdApproveTime)}</p>
                                                    </div>


                                                ) : (
                                                    <div className='panelBtnCOntainer'>
                                                        <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick3(selectedOrder.id)} >Stornieren</button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='modalButton'>


                                                <button className='panelBtnFilterFix' onClick={() => handleGeneratePDF(selectedOrder)}>PDF</button>

                                            </div>

                                        </div>

                                    </div>
                                </div>




                                <hr />



                                <div className='orderMotorcycle'>
                                    <div>
                                        <label className='radioContainer'>

                                            <div className='modalIn' style={{
                                                backgroundColor: statusesMotorcycle1[selectedOrder.id] ? selectedOrder.motorcycle1.Color : '#d2d2d2',
                                                padding: '10px',
                                                borderRadius: '3px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '250px',
                                                height: '120px'



                                            }}>
                                                <p style={{ fontWeight: statusesMotorcycle1[selectedOrder.id] ? 'bold' : 'normal', color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                    {selectedOrder.motorcycle1.Name}
                                                </p>
                                                <input
                                                    className='radioLine'
                                                    type="radio"
                                                    name={`motorcycle1-${selectedOrder.id}`}
                                                    checked={statusesMotorcycle1[selectedOrder.id]}
                                                    onChange={() => handleMotorcycle1StatusChange(selectedOrder.id)}
                                                    disabled={(selectedOrder.firstApprove) || (selectedOrder.status) || (selectedOrder.thirdApprove)}
                                                />
                                                <p>
                                                    Kehnzeichen:
                                                </p>
                                                <p>
                                                    FIN:
                                                </p>
                                                {/* <p>
                                            id: {selectedOrder.motorcycle1.id}
                                        </p> */}
                                                <p>
                                                    total
                                                    € {selectedOrder.price},-
                                                </p>
                                                {/* <div style={{
                                            width: '50px',
                                            height: '3px',
                                            backgroundColor: selectedOrder.motorcycle1.Color,
                                            borderRadius: '30px', 
                                            display: 'inline-block', 
                                        }}>

                                        </div> */}

                                            </div>

                                            {/* {order.motorcycle1.Name} */}
                                        </label>
                                    </div>
                                    <div>

                                        {selectedOrder.selectedMotorcycle2 ? (
                                            // If a second motorcycle exists
                                            <label className='radioContainer'>

                                                <div className='modalIn' style={{
                                                    backgroundColor: statusesMotorcycle2[selectedOrder.id] ? selectedOrder.motorcycle2.Color : '#d2d2d2',
                                                    padding: '10px',
                                                    borderRadius: '3px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '250px',
                                                    height: '120px'

                                                }}>
                                                    <p style={{ fontWeight: statusesMotorcycle2[selectedOrder.id] ? 'bold' : 'normal', color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                        {selectedOrder.motorcycle2.Name}
                                                    </p>
                                                    <input
                                                        className='radioLine'

                                                        type="radio"
                                                        name={`motorcycle2-${selectedOrder.id}`}
                                                        checked={statusesMotorcycle2[selectedOrder.id]}
                                                        onChange={() => handleMotorcycle2StatusChange(selectedOrder.id)}
                                                        disabled={(selectedOrder.firstApprove) || (selectedOrder.status) || (selectedOrder.thirdApprove)}
                                                    />
                                                    <p>
                                                        Kehnzeichen
                                                    </p>
                                                    <p>
                                                        VIN
                                                    </p>
                                                    {/* <p>
                                                id:  {selectedOrder.motorcycle2.id}
                                            </p> */}
                                                    <p>
                                                        total
                                                        € {selectedOrder.price2},-
                                                    </p>
                                                    {/* <div style={{
                                                width: '50px',
                                                height: '3px',
                                                backgroundColor: selectedOrder.motorcycle2.Color,
                                                borderRadius: '30px', 
                                                display: 'inline-block', 
                                            }}>

                                            </div> */}


                                                </div>


                                            </label>
                                        ) : (
                                            // If there is no second motorcycle
                                            // <p>Nicht ausgewählt</p>
                                            <p></p>

                                        )}

                                    </div>
                                </div>
                          


                                <hr />
                                {/* <div className='orderMotorcycle'>
                            <p>Bestätigung</p>
                            <div className={`toggle-switch ${initialStatuses[selectedOrder.id] && statuses[selectedOrder.id] ? 'disabled' : ''}`}>
                                <input type="checkbox" className="toggle-input" checked={statuses[selectedOrder.id]} readOnly />
                                <label className="toggle-label">
                                    <div className="toggle-inner"></div>
                                    <div className="toggle-switched"></div>
                                </label>
                            </div>
                        </div> */}
                                {/* <hr /> */}
                                <div className='modalInfoBlock'>
                                    <div className='blockLeft'>
                                        <div>
                                            <p>
                                                Tarif:
                                            </p>
                                            <p>
                                                <b> {selectedOrder.selectedRate === 'daily' ? 'Tagesmieten' : selectedOrder.selectedRate === 'weekly' ? 'Wochenendmieten' : 'Sonderanfragen/Kombinationen'} </b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                von:
                                            </p>
                                            <p>
                                                <b>{formatDate(selectedOrder.startDate)} / {formatTime(selectedOrder.startTime)}</b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                bis:
                                            </p>
                                            <p>
                                                <b>{formatDate(selectedOrder.endDate)} / {selectedOrder.endTime}</b>
                                            </p>
                                        </div>

                                    </div>

                                    <div className='blockLeft'>

                                        <div>
                                            <p>
                                                name:
                                            </p>
                                            <p>
                                                <b>{selectedOrder.firstName} {selectedOrder.lastName}</b>

                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                email:
                                            </p>
                                            <p>
                                                <b>{selectedOrder.email}</b>

                                            </p>
                                        </div>
                                        <div>
                                            <p>

                                                nummer:
                                            </p>
                                            <p>
                                                <b>{selectedOrder.phoneNumber}</b>

                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div>

                                    {/* <div className='modalBlock'>
                               <div>
                                   <p>
                                         {selectedOrder.selectedMotorcycle}
                                 </p>
                                 <p>
                                   total
                                    € {selectedOrder.price},-
                                       </p>
                                    </div>
                                 <div>
                                   <p>
                                  {selectedOrder.selectedMotorcycle2}
                                 </p>
                               <p>
                                 total
                                    € {selectedOrder.price2},-
                                  </p>
                                 </div>

                               </div> */}


                                    {selectedOrder.documentType && (
                                        <div className='modalInfoBlock'>
                                            <div className='blockLeft'>
                                                <div>
                                                    <p>
                                                        ausweisart:
                                                    </p>
                                                    <p>
                                                        <b>
                                                            {selectedOrder.documentType}

                                                        </b>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        ausweisnummer:
                                                    </p>
                                                    <p>
                                                        <b>
                                                            {selectedOrder.documentNumber}

                                                        </b>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        land:
                                                    </p>
                                                    <p>
                                                        <b>
                                                            {selectedOrder.country}

                                                        </b>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='blockLeft'>
                                                <div>
                                                    <p>
                                                        führerscheinnummer:
                                                    </p>
                                                    <p>
                                                        <b>
                                                            {selectedOrder.driverLicenseNumber}

                                                        </b>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        führerscheinklasse:
                                                    </p>
                                                    <p>
                                                        <b>
                                                            {selectedOrder.driverLicenseClass}

                                                        </b>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        führerschein seit:
                                                    </p>
                                                    <p>
                                                        <b>
                                                            {selectedOrder.driverLicenseSince}

                                                        </b>
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    )}





                                </div>


                                <div className="matching-orders">

                                    {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders1.length > 0 && (
                                        <>
                                            <td>
                                                <th>
                                                    <h5>Motorrad {selectedOrder.selectedMotorcycle} auch gebucht:</h5>
                                                </th>
                                                {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders1.map(order => (
                                                    <tr key={order.id} onClick={() => handleOrderClick(order)} style={{ cursor: 'pointer' }}>
                                                        Buchung {order.id}: von {formatDate(order.startDate)} / {formatTime(order.startTime)} | bis {formatDate(order.endDate)} / {formatTime(order.endTime)} / (<span className={order.status ? 'confirmed' : 'not-confirmed'}>{order.status ? 'bestätigt' : 'nicht bestätigt'}</span>)
                                                    </tr>
                                                ))}
                                            </td>
                                        </>
                                    )}



                                    {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders2.length > 0 && (
                                        <>
                                            <td>
                                                <th>
                                                    <h5>Alternativ {selectedOrder.selectedMotorcycle2} auch gebucht:</h5>
                                                </th>
                                                {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders2.map(order => (
                                                    <tr key={order.id} onClick={() => handleOrderClick(order)} style={{ cursor: 'pointer' }}>
                                                        Buchung {order.id}: von {formatDate(order.startDate)} / {formatTime(order.startTime)} | bis {formatDate(order.endDate)} / {formatTime(order.endTime)} / (<span className={order.status ? 'confirmed' : 'not-confirmed'}>{order.status ? 'bestätigt' : 'nicht bestätigt'}</span>)
                                                    </tr>
                                                ))}
                                            </td>
                                        </>
                                    )}

                                </div>









                                {/* <button className={`btn ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick(selectedOrder.id)} disabled={selectedOrder.status}>
                            Bestätigung senden</button> */}


                                {/* <div className='modalBtnContainer'>
                            <div>
                                {selectedOrder.firstApprove ? (
                                    <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                        <div className='panelIcon'>

                                            <p style={{ color: selectedOrder.thirdApprove ? 'red' : 'green' }}><b>Motorrad Bestätigt</b>
                                            </p>
                                            <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: selectedOrder.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>


                                        </div>
                                        <p className='approve'>{selectedOrder.firstApprove}</p>
                                        <p className='approve'>{formatDate2(selectedOrder.firstApproveTime)}</p>
                                    </div>


                                ) : (

                                    <div className='panelBtnCOntainer'>
                                        <button className={`panelBtn ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick(selectedOrder.id)} disabled={!isOrderChanged[selectedOrder.id] || selectedOrder.status}
                                        >Motorrad bestätigen</button>
                                    </div>
                                )}
                            </div>

                            <div>
                                {selectedOrder.secondApprove ? (
                                    <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                        <div className='panelIcon'>
                                            <p style={{ color: selectedOrder.thirdApprove ? 'red' : 'green' }}><b>Auftrag fixiert</b>
                                            </p>
                                            <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: selectedOrder.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>

                                        </div>
                                        <p className='approve'>{selectedOrder.secondApprove}</p>
                                        <p className='approve'>{formatDate2(selectedOrder.secondApproveTime)}</p>
                                    </div>


                                ) : (
                                    <div className='panelBtnCOntainer'>
                                        <button className={`panelBtn ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick2(selectedOrder.id)} disabled={(!selectedOrder.statusMotorcycle1 && !selectedOrder.statusMotorcycle2) || (!selectedOrder.agb) || (selectedOrder.thirdApprove)}>Bestätigung senden</button>

                                    </div>
                                )}
                            </div>
                            <div>
                                {selectedOrder.thirdApprove ? (
                                    <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                        <div className='panelIcon'>
                                            <p><b>Auftrag storniert </b></p>
                                            <i class="fa fa-ban" aria-hidden="true" style={{ marginLeft: '8px', fontSize: '18px' }}></i>
                                        </div>

                                        <p className='approve'>{selectedOrder.thirdApprove}</p>
                                        <p className='approve'>{formatDate2(selectedOrder.thirdApproveTime)}</p>
                                    </div>


                                ) : (
                                    <div className='panelBtnCOntainer'>
                                        <button className={`panelBtn ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick3(selectedOrder.id)} >Stornieren</button>
                                    </div>
                                )}
                            </div>

                        </div> */}





                            </div>









                        </div>
                    )}




                    <table className="responsive-table">
                        <thead className='headline'>
                            <tr>
                                <th className='panelNo'>№</th>
                                <th className='panelStatus'>Status</th>
                                <th className='panelMot'>1. Motorrad</th>
                                <th className='panelMot'>Alternativ</th>
                                {/* <th>ID</th> */}
                                <th className='panelName'>Name</th>
                                <th className='panelDate'>von</th>
                                <th className='panelDate'>bis</th>
                                <th className='auftrag'></th>
                                <th className='auftrag'>Auftrag fixierung</th>
                                <th>Verträge drucken</th>
                                <th style={{ width: '10%' }}>Stornierung</th>



                            </tr>

                        </thead>
                        <tbody>
                            {filteredOrders.slice().reverse().map(order => (
                                <tr key={order.id}>



                                    <td className='tdId' style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                        {order.id}
                                    </td>
                                    {/* <td className='ampel'>
                                    <div className="light-container">
                                        <div className={`light ${(!order.statusMotorcycle1 && !order.statusMotorcycle2 && !order.status) ||
                                            (order.statusMotorcycle2 === null || order.statusMotorcycle2 === undefined) ? 'red-light' : ''}`}
                                        >

                                        </div>
                                        <div className='dark-red-light'></div>

                                        <div className={`light ${(!order.statusMotorcycle1 && !order.statusMotorcycle2 && !order.status) ||
                                            (order.statusMotorcycle2 === null || order.statusMotorcycle2 === undefined) ? '' :
                                            (order.statusMotorcycle1 || order.statusMotorcycle2) && !order.status ||
                                                (order.statusMotorcycle2 === null || order.statusMotorcycle2 === undefined) ? 'yellow-light' : ''}`}
                                        ></div>
                                        <div className='dark-yellow-light'></div>

                                        <div className={`light ${(!order.statusMotorcycle1 && !order.statusMotorcycle2 && !order.status) ||
                                            (order.statusMotorcycle2 === null || order.statusMotorcycle2 === undefined) ? '' :
                                            (order.statusMotorcycle1 || order.statusMotorcycle2) && order.status ||
                                                (order.statusMotorcycle2 === null || order.statusMotorcycle2 === undefined) ? 'green-light' : ''}`}
                                        ></div>
                                        <div className='dark-green-light'></div>

                                    </div>

                                 </td> */}

                                    <td className='ampel'>
                                        <div className="light-container">
                                            <div
                                                className={`light ${order.thirdApprove ? 'red-light' :
                                                    ((!order.statusMotorcycle1 && !order.statusMotorcycle2 && !order.status) ||
                                                        (order.statusMotorcycle2 === null || order.statusMotorcycle2 === undefined) ? 'blue-light' :
                                                        (order.statusMotorcycle1 || order.statusMotorcycle2) && !order.status ||
                                                            (order.statusMotorcycle2 === null || order.statusMotorcycle2 === undefined) ? 'yellow-light' :
                                                            'green-light')}`}
                                            >
                                            </div>
                                        </div>
                                    </td>



                                    <td className='td1m'>
                                        <label className='radioContainer'>

                                            <div >



                                                <div className='motoHover' style={{
                                                    width: '97%',
                                                    backgroundColor: statusesMotorcycle1[order.id] ? order.motorcycle1.Color : '#d7d7d7',
                                                    borderRadius: '3px', 
                                                    display: 'inline-block', 
                                                    padding: '0 0 0 5px',
                                                    marginTop: '5px'

                                                }}>
                                                    <p style={{ fontWeight: statusesMotorcycle1[order.id] ? 'bold' : 'normal', color: order.thirdApprove ? 'red' : 'inherit', marginBottom: '4px', width: '150px', fontSize: '11px' }}>
                                                        {order.motorcycle1.Name}
                                                    </p>
                                                    <input
                                                        className='radioLine2'
                                                        type="radio"
                                                        name={`motorcycle1-${order.id}`}
                                                        checked={statusesMotorcycle1[order.id]}
                                                        onChange={() => handleMotorcycle1StatusChange(order.id)}
                                                        disabled={(order.firstApprove) || (order.status) || (order.thirdApprove)}
                                                    />
                                                    <p>
                                                        Kennzeichen: {order.motorcycle1.kennzeichen}
                                                    </p>
                                                    <p>
                                                        FIN: {order.motorcycle1.fin}
                                                    </p>
                                                    {/* <p>
                                                        id: {order.motorcycle1.id}
                                                    </p> */}
                                                </div>
                                            </div>

                                            {/* {order.motorcycle1.Name} */}
                                        </label>
                                    </td>
                                    <td className='td2m'>
                                        {order.motorcycle2 ? (
                                            // If a second motorcycle exists
                                            <label className='radioContainer'>


                                                <div>



                                                    <div className='motoHover' style={{
                                                        width: '100%',
                                                        backgroundColor: statusesMotorcycle2[order.id] ? order.motorcycle2.Color : '#d7d7d7',
                                                        borderRadius: '3px', 
                                                        display: 'inline-block', 
                                                        padding: '0 0 0 5px',
                                                        marginTop: '5px'
                                                    }}>

                                                        <p style={{ fontWeight: statusesMotorcycle2[order.id] ? 'bold' : 'normal', color: order.thirdApprove ? 'red' : 'inherit', marginBottom: '4px', width: '150px', fontSize: '11px' }}>
                                                            {order.motorcycle2.Name}
                                                        </p>
                                                        <input
                                                            className='radioLine2'

                                                            type="radio"
                                                            name={`motorcycle2-${order.id}`}
                                                            checked={statusesMotorcycle2[order.id]}
                                                            onChange={() => handleMotorcycle2StatusChange(order.id)}
                                                            disabled={(order.firstApprove) || (order.status) || (order.thirdApprove)}
                                                        />
                                                        <p>
                                                            Kennzeichen: {order.motorcycle2.kennzeichen}
                                                        </p>
                                                        <p>
                                                            FIN: {order.motorcycle2.fin}
                                                        </p>
                                                        {/* <p>
                                                            id:  {order.motorcycle2.id}
                                                        </p> */}
                                                    </div>


                                                </div>


                                            </label>
                                        ) : (
                                            // If there is no second motorcycle
                                            <p style={{ color: 'red' }}>Nicht ausgewählt</p>
                                        )}

                                    </td>

                                    {/* <td onClick={() => handleOrderClick(order)}>
                                    {order.id}
                                 </td> */}

                                    {/* <td onClick={() => handleOrderClick(order)}>
                                    {order.selectedMotorcycle2 ? order.selectedMotorcycle2 : "Nicht Ausgewählt"}
                                 </td> */}
                                    <td className='tdName'
                                        style={{ cursor: 'pointer', position: 'relative' }} onClick={() => handleOrderClick(order)}>
                                        <p style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                            {order.firstName}
                                        </p>
                                        <p style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                            {order.lastName}
                                        </p>
                                        {/* {order.selectedRate === 'daily' ? 'Tagesmieten' : order.selectedRate === 'weekly' ? 'Wochenendmieten' : 'Sonderanfragen/Kombinationen'} */}
                                        <p style={{ color: order.thirdApprove ? 'red' : 'inherit', fontSize: '11px' }}>
                                            {order.phoneNumber}
                                        </p>
                                        {/* {order.selectedRate === 'daily' ? 'Tagesmieten' : order.selectedRate === 'weekly' ? 'Wochenendmieten' : 'Sonderanfragen/Kombinationen'} */}
                                        <div className="tooltip2">
                                            Info
                                        </div>
                                    </td>

                                    <td className='tdDateVon'
                                        style={{ cursor: 'pointer', position: 'relative' }}
                                        onClick={() => toggleViewMode2(order.id)}
                                    >
                                        <div style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                            <div>
                                                {formatDateDay(order.startDate)}
                                            </div>
                                            <div>
                                                {formatDateMY(order.startDate)}
                                            </div>
                                            <div>
                                                {formatTime(order.startTime)}
                                            </div>
                                        </div>
                                        {/* Всплывающее окно */}
                                        <div className="tooltip">
                                            Zum Kalendar
                                        </div>
                                    </td>



                                    <td className='tdDateBis'
                                        style={{ cursor: 'pointer', position: 'relative' }} onClick={() => toggleViewMode2(order.id)}>
                                        <div style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                            <div>
                                                {formatDateDay(order.endDate)}
                                            </div>
                                            <div>
                                                {formatDateMY(order.endDate)}
                                            </div>
                                            <div>
                                                {formatTime(order.endTime)}
                                            </div>

                                        </div>
                                        <div className="tooltip">
                                            Zum Kalendar
                                        </div>
                                    </td>


                                    <td>


                                        {order.firstApprove ? (
                                            <div style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                                <div className='panelIcon'>

                                                    <p style={{ color: order.thirdApprove ? 'red' : 'green' }}><b>Motorrad Bestätigt</b>
                                                    </p>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: order.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>


                                                </div>
                                                <p className='approve'>{order.firstApprove}</p>
                                                <p className='approve'>{formatDate2(order.firstApproveTime)}</p>
                                            </div>


                                        ) : (

                                            <div className='panelBtnCOntainer'>
                                                <button className={`panelBtnFilterFix ${order.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick(order.id)} disabled={!isOrderChanged[order.id] || order.status}
                                                >Motorrad bestätigen</button>
                                            </div>
                                        )}
                                    </td>

                                    <td>


                                        {order.secondApprove ? (
                                            <div style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                                <div className='panelIcon'>
                                                    <p style={{ color: order.thirdApprove ? 'red' : 'green' }}><b>Auftrag fixiert</b>
                                                    </p>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: order.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>

                                                </div>
                                                <p className='approve'>{order.secondApprove}</p>
                                                <p className='approve'>{formatDate2(order.secondApproveTime)}</p>
                                            </div>


                                        ) : (
                                            <div className='panelBtnCOntainer'>
                                                <button className={`panelBtnFilterFix ${order.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick2(order.id)} disabled={(!order.statusMotorcycle1 && !order.statusMotorcycle2) || (!order.agb) || (order.thirdApprove)}>Bestätigung senden</button>

                                            </div>
                                        )}

                                    </td>
                                    <td>
                                        <button className='panelBtnFilter2' onClick={() => handleGeneratePDF(order)} disabled={(!order.statusMotorcycle1 && !order.statusMotorcycle2) || (!order.agb) || (!order.secondApprove)}>PDF</button>
                                    </td>
                                    <td>


                                        {order.thirdApprove ? (
                                            <div style={{ color: order.thirdApprove ? 'red' : 'inherit' }}>
                                                <div className='panelIcon'>
                                                    <p><b>Auftrag storniert </b></p>
                                                    <i class="fa fa-ban" aria-hidden="true" style={{ marginLeft: '8px', fontSize: '18px' }}></i>
                                                </div>

                                                <p className='approve'>{order.thirdApprove}</p>
                                                <p className='approve'>{formatDate2(order.thirdApproveTime)}</p>
                                            </div>


                                        ) : (
                                            <div className='panelBtnCOntainer'>
                                                <button style={{ width: '100px' }} className={`panelBtnFilterFix ${order.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick3(order.id)} >Stornieren</button>
                                            </div>
                                        )}

                                    </td>




                                    {/* <td onClick={() => toggleStatus(order.id)}>
                                    <div className={`toggle-switch ${initialStatuses[order.id] && statuses[order.id] ? 'disabled' : ''}`}>
                                        <input type="checkbox" className="toggle-input" checked={statuses[order.id]} readOnly />
                                        <label className="toggle-label">
                                            <div className="toggle-inner"></div>
                                            <div className="toggle-switched"></div>
                                        </label>

                                    </div>
                                 </td> */}


                                    <div>

                                    </div>
                                </tr>

                            ))}
                        </tbody>

                    </table>
                    <div>
                        {selectedOrderForPDF && (
                            // <div className='pdf-modal'>
                            //     <div className='pdf-modal-content'>
                            //         <button className='close-btn' onClick={handleClosePDF}>&times;</button>
                            //         <PDFComponent orderData={selectedOrderForPDF} userFullName={userFullName}/>
                            //         <PDFComponent2 orderData={selectedOrderForPDF} />
                            //         <PDFComponent3 orderData={selectedOrderForPDF} />

                            //     </div>

                            // </div>
                            <div className='pdf-modal'>
                                <div className='pdf-modal-content' style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <button className='close-btn' onClick={handleClosePDF}>&times;</button>
                                    <p>Buchungs-ID {selectedOrderForPDF.id}</p>
                                    <div className='pdfBtnContainer'>
                                        <PDFComponent orderData={selectedOrderForPDF} userFullName={userFullName} />
                                        <PDFComponent2 orderData={selectedOrderForPDF} userFullName={userFullName} />
                                        <PDFComponent3 orderData={selectedOrderForPDF} userFullName={userFullName} />
                                    </div>

                                    <hr style={{ color: 'black', width: '100%', marginTop: '15px' }}></hr>
                                    <div className='columns'>
                                        {['Mietvertrag', 'Benutzungsbewilligung', 'Ubernahme'].map((docType) => (
                                            groupedRecords[docType] ? (
                                                <div key={docType} className='column'>
                                                    <p>{docType}</p>
                                                    {groupedRecords[docType].slice().reverse().map((record, index) => (
                                                        <div key={index}>
                                                            {record.records_id && (
                                                                <div>
                                                                    {Array.isArray(record.records_id) ? (
                                                                        record.records_id.slice().reverse().map((item, idx) => (
                                                                            <div key={idx}>
                                                                                <p>Name: {item.name}</p>
                                                                                <p>Date: {item.date}</p>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <table className='recordsTable'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ fontSize: '10px' }}>{record.records_id.name}</th>
                                                                                    <th style={{ fontSize: '11px' }}>{formatDate3(record.records_id.date)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                        </table>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null
                                        ))}
                                    </div>

                                </div>
                            </div>

                        )}
                    </div>

                </div>


            ) : (

                //CALENDAR

                <div className='calendar'>
                    <Calendar
                        // className="dark-calendar"

                        localizer={localizer}
                        events={filteredOrders.map(order => ({
                            id: order.id,
                            start: new Date(`${order.startDate}T${order.startTime}`),
                            end: new Date(`${order.endDate}T${order.endTime}`),
                            // title: `${order.id} / ${order.selectedMotorcycle} / ${order.firstName}`,
                            title: getEventTitle(order), 
                            status: order.status,
                            motorcycleColor: getMotorcycleColor(order), 
                            style: getMotorcycleColor(order)


                        }))}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 700, width: 'auto' }}

                        date={calendarDate} 
                        onNavigate={(date) => setCalendarDate(date)} 
                        onSelectEvent={(event) => {
                            setSelectedOrderId(event.id);
                            setHighlightedEvent(event.id); 
                            handleOrderClick(event); 

                        }}
                        eventPropGetter={(event) => ({
                            style: {
                                // backgroundColor: event.id === highlightedEvent ? 'rgba(128, 0, 128, 0.5)' : event.status ? 'green' : 'red',
                                border: event.id === highlightedEvent ? '8px solid rgb(255 0 198)' : 'none',
                                boxShadow: event.id === highlightedEvent ? '0 0 10px #ff00f5' : 'none',
                                backgroundColor: event.motorcycleColor, 
                                ...event.style 

                            }
                        })}
                        views={['week', 'day', 'month']} 
                        defaultView={view} 

                    />

                    <div>
                        {selectedOrderForPDF && (
                            // <div className='pdf-modal'>
                            //     <div className='pdf-modal-content'>
                            //         <button className='close-btn' onClick={handleClosePDF}>&times;</button>
                            //         <PDFComponent orderData={selectedOrderForPDF} userFullName={userFullName}/>
                            //         <PDFComponent2 orderData={selectedOrderForPDF} />
                            //         <PDFComponent3 orderData={selectedOrderForPDF} />

                            //     </div>

                            // </div>
                            <div className='pdf-modal'>
                                <div className='pdf-modal-content' style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <button className='close-btn' onClick={handleClosePDF}>&times;</button>
                                    <p>Buchungs-ID {selectedOrderForPDF.id}</p>
                                    <div className='pdfBtnContainer'>
                                        <PDFComponent orderData={selectedOrderForPDF} userFullName={userFullName} />
                                        <PDFComponent2 orderData={selectedOrderForPDF} userFullName={userFullName} />
                                        <PDFComponent3 orderData={selectedOrderForPDF} userFullName={userFullName} />
                                    </div>

                                    <hr style={{ color: 'black', width: '100%', marginTop: '15px' }}></hr>
                                    <div className='columns'>
                                        {['Mietvertrag', 'Benutzungsbewilligung', 'Ubernahme'].map((docType) => (
                                            groupedRecords[docType] ? (
                                                <div key={docType} className='column'>
                                                    <p>{docType}</p>
                                                    {groupedRecords[docType].slice().reverse().map((record, index) => (
                                                        <div key={index}>
                                                            {record.records_id && (
                                                                <div>
                                                                    {Array.isArray(record.records_id) ? (
                                                                        record.records_id.slice().reverse().map((item, idx) => (
                                                                            <div key={idx}>
                                                                                <p>Name: {item.name}</p>
                                                                                <p>Date: {item.date}</p>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <table className='recordsTable'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ fontSize: '10px' }}>{record.records_id.name}</th>
                                                                                    <th style={{ fontSize: '11px' }}>{formatDate3(record.records_id.date)}</th>
                                                                                </tr>
                                                                            </thead>
                                                                        </table>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null
                                        ))}
                                    </div>

                                </div>
                            </div>

                        )}
                    </div>
                </div>








                // MODAL


            )}
            {selectedOrder && (
                <div className="order-modal">
                    <div className="order-details">
                        <button className="close-button" onClick={() => setSelectedOrder(null)}>x</button>


                        <div className='modalInfoHead'>


                            <div>
                                <h2 onClick={() => toggleViewMode2(selectedOrder.id)} style={{ cursor: 'pointer' }}
                                >Buchung № {selectedOrder.id}</h2>

                                <p>
                                    {selectedOrder.status
                                        ? 'Beim buchung war ausgewählt:'
                                        : 'Wählen Sie zur Bestätigung ein Motorrad aus:'}
                                </p>
                                <p style={{ fontSize: '13px' }}>
                                    (zusatzpaket: {selectedOrder.zusatzpaket ? '€ 69,-' : 'kein'})
                                </p>
                            </div>



                            <div>
                                <div className='modalBtnContainer2'>
                                    <div className='modalButton'>
                                        {selectedOrder.firstApprove ? (
                                            <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                <div className='panelIcon2'>

                                                    <p style={{ color: selectedOrder.thirdApprove ? 'red' : 'green' }}><b>Motorrad Bestätigt</b>
                                                    </p>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: selectedOrder.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>


                                                </div>
                                                <p className='approve2'>{selectedOrder.firstApprove}</p>
                                                <p className='approve2'>{formatDate2(selectedOrder.firstApproveTime)}</p>
                                                <hr />
                                            </div>


                                        ) : (

                                            <div className='panelBtnCOntainer'>
                                                <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick(selectedOrder.id)} disabled={!isOrderChanged[selectedOrder.id] || selectedOrder.status}
                                                >Motorrad bestätigen</button>
                                            </div>
                                        )}
                                    </div>

                                    <div className='modalButton'>
                                        {selectedOrder.secondApprove ? (
                                            <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                <div className='panelIcon2'>
                                                    <p style={{ color: selectedOrder.thirdApprove ? 'red' : 'green' }}><b>Auftrag fixiert</b>
                                                    </p>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" style={{ color: selectedOrder.thirdApprove ? 'red' : 'green', marginLeft: '8px', fontSize: '18px' }}></i>

                                                </div>
                                                <p className='approve2'>{selectedOrder.secondApprove}</p>
                                                <p className='approve2'>{formatDate2(selectedOrder.secondApproveTime)}</p>
                                                <hr />
                                            </div>


                                        ) : (
                                            <div className='panelBtnCOntainer'>
                                                <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick2(selectedOrder.id)} disabled={(!selectedOrder.statusMotorcycle1 && !selectedOrder.statusMotorcycle2) || (!selectedOrder.agb) || (selectedOrder.thirdApprove)}>Bestätigung senden</button>

                                            </div>
                                        )}
                                    </div>
                                    <div className='modalButton'>
                                        {selectedOrder.thirdApprove ? (
                                            <div style={{ color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                <div className='panelIcon2'>
                                                    <p><b>Auftrag storniert </b></p>
                                                    <i class="fa fa-ban" aria-hidden="true" style={{ marginLeft: '8px', fontSize: '18px' }}></i>
                                                </div>

                                                <p className='approve2'>{selectedOrder.thirdApprove}</p>
                                                <p className='approve2'>{formatDate2(selectedOrder.thirdApproveTime)}</p>
                                            </div>


                                        ) : (
                                            <div className='panelBtnCOntainer'>
                                                <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick3(selectedOrder.id)} >Stornieren</button>
                                            </div>
                                        )}
                                    </div>
                                    <div className='modalButton'>


                                        <button className='panelBtnFilterFix' onClick={() => handleGeneratePDF(selectedOrder)}>PDF</button>

                                    </div>

                                </div>

                            </div>
                        </div>




                        <hr />



                        <div className='orderMotorcycle'>
                            <div>
                                <label className='radioContainer'>

                                    <div className='modalIn' style={{
                                        backgroundColor: statusesMotorcycle1[selectedOrder.id] ? selectedOrder.motorcycle1.Color : '#d2d2d2',
                                        padding: '10px',
                                        borderRadius: '3px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '250px',
                                        height: '120px'



                                    }}>
                                        <p style={{ fontWeight: statusesMotorcycle1[selectedOrder.id] ? 'bold' : 'normal', color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                            {selectedOrder.motorcycle1.Name}
                                        </p>
                                        <input
                                            className='radioLine'
                                            type="radio"
                                            name={`motorcycle1-${selectedOrder.id}`}
                                            checked={statusesMotorcycle1[selectedOrder.id]}
                                            onChange={() => handleMotorcycle1StatusChange(selectedOrder.id)}
                                            disabled={(selectedOrder.firstApprove) || (selectedOrder.status) || (selectedOrder.thirdApprove)}
                                        />
                                        <p>
                                            Kehnzeichen: {selectedOrder.motorcycle1.kennzeichen}
                                        </p>
                                        <p>
                                            FIN: {selectedOrder.motorcycle1.fin}
                                        </p>

                                        <p>
                                            total
                                            € {selectedOrder.price},-
                                        </p>


                                    </div>

                                </label>
                            </div>
                            <div>

                                {selectedOrder.selectedMotorcycle2 ? (
                                    <label className='radioContainer'>

                                        <div className='modalIn' style={{
                                            backgroundColor: statusesMotorcycle2[selectedOrder.id] ? selectedOrder.motorcycle2.Color : '#d2d2d2',
                                            padding: '10px',
                                            borderRadius: '3px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '250px',
                                            height: '120px'

                                        }}>
                                            <p style={{ fontWeight: statusesMotorcycle2[selectedOrder.id] ? 'bold' : 'normal', color: selectedOrder.thirdApprove ? 'red' : 'inherit' }}>
                                                {selectedOrder.motorcycle2.Name}
                                            </p>
                                            <input
                                                className='radioLine'

                                                type="radio"
                                                name={`motorcycle2-${selectedOrder.id}`}
                                                checked={statusesMotorcycle2[selectedOrder.id]}
                                                onChange={() => handleMotorcycle2StatusChange(selectedOrder.id)}
                                                disabled={(selectedOrder.firstApprove) || (selectedOrder.status) || (selectedOrder.thirdApprove)}
                                            />
                                            <p>
                                                Kehnzeichen: {selectedOrder.motorcycle2.kennzeichen}
                                            </p>
                                            <p>
                                                FIN: {selectedOrder.motorcycle2.fin}
                                            </p>

                                            <p>
                                                total
                                                € {selectedOrder.price2},-
                                            </p>



                                        </div>


                                    </label>
                                ) : (
                                    <p></p>

                                )}

                            </div>
                        </div>


                        <hr />

                        <div className='modalInfoBlock'>
                            <div className='blockLeft'>
                                <div>
                                    {/* <p>
                                        
                                    </p> */}
                                    <p>
                                        Tarif: <b> {selectedOrder.selectedRate === 'daily' ? 'Tagesmieten' : selectedOrder.selectedRate === 'weekly' ? 'Wochenendmieten' : 'Sonderanfragen/Kombinationen'} </b>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        von:
                                    </p>
                                    <p>
                                        <b>{formatDate(selectedOrder.startDate)} / {formatTime(selectedOrder.startTime)}</b>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        bis:
                                    </p>
                                    <p>
                                        <b>{formatDate(selectedOrder.endDate)} / {formatTime(selectedOrder.endTime)}</b>
                                    </p>
                                </div>

                            </div>

                            <div className='blockLeft'>

                                <div>
                                    <p>
                                        name:
                                    </p>
                                    <p>
                                        <b>{selectedOrder.firstName} {selectedOrder.lastName}</b>

                                    </p>
                                </div>
                                <div>
                                    <p>
                                        email:
                                    </p>
                                    <p>
                                        <b>{selectedOrder.email}</b>

                                    </p>
                                </div>
                                <div>
                                    <p>

                                        nummer:
                                    </p>
                                    <p>
                                        <b>{selectedOrder.phoneNumber}</b>

                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div>




                            {selectedOrder.documentType && (
                                <div className='modalInfoBlock'>
                                    <div className='blockLeft'>
                                        <div>
                                            <p>
                                                ausweisart:
                                            </p>
                                            <p>
                                                <b>
                                                    {selectedOrder.documentType}

                                                </b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                ausweisnummer:
                                            </p>
                                            <p>
                                                <b>
                                                    {selectedOrder.documentNumber}

                                                </b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                ausstellungsbehörde:
                                            </p>
                                            <p>
                                                <b>
                                                    {selectedOrder.ausweisB}
                                                </b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                land:
                                            </p>
                                            <p>
                                                <b>
                                                    {selectedOrder.country}

                                                </b>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='blockLeft'>
                                        <div>
                                            <p>
                                                führerscheinnummer:
                                            </p>
                                            <p>
                                                <b>
                                                    {selectedOrder.driverLicenseNumber}

                                                </b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                führerscheinklasse:
                                            </p>
                                            <p>
                                                <b>
                                                    {selectedOrder.driverLicenseClass}

                                                </b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                ausstellungsbehörde:
                                            </p>
                                            <p>
                                                <b>
                                                    {selectedOrder.itionalInfo}

                                                </b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                datum:
                                            </p>
                                            <p>
                                                <b>
                                                    {formatDateOnly(selectedOrder.thirdDate)}

                                                </b>
                                            </p>
                                        </div>
                                    </div>




                                </div>
                            )}
                            <div className='butContainer'>
                                <h3>Email wieder senden:</h3>
                                <div className='butSection'>
                                    <div className='panelBtnCOntainer'>
                                        <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick1email(selectedOrder.id)}
                                        >1 E-MAIL</button>
                                    </div>

                                    <div className='panelBtnCOntainer'>
                                        <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick2email(selectedOrder.id)} disabled={(!selectedOrder.statusMotorcycle1 && !selectedOrder.statusMotorcycle2) || selectedOrder.status}
                                        >2 E-MAIL (mit link)</button>
                                    </div>

                                    <div className='panelBtnCOntainer'>
                                        <button className={`panelBtnFilterFix ${selectedOrder.status ? 'disabled' : ''}`} onClick={() => handleConfirmationButtonClick3email(selectedOrder.id)} disabled={(!selectedOrder.statusMotorcycle1 && !selectedOrder.statusMotorcycle2) || (!selectedOrder.agb) || (selectedOrder.thirdApprove)}
                                        >3 E-MAIL (letzte)</button>
                                    </div>
                                </div>
                            </div>




                        </div>


                        <div className="matching-orders">

                            {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders1.length > 0 && (
                                <>
                                    <td>
                                        <th>
                                            <h5>Motorrad {selectedOrder.selectedMotorcycle} auch gebucht:</h5>
                                        </th>
                                        {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders1.map(order => (
                                            <tr key={order.id} onClick={() => handleOrderClick(order)} style={{ cursor: 'pointer' }}>
                                                Buchung {order.id}: von {formatDate(order.startDate)} / {formatTime(order.startTime)} | bis {formatDate(order.endDate)} / {formatTime(order.endTime)} / (<span className={order.status ? 'confirmed' : 'not-confirmed'}>{order.status ? 'bestätigt' : 'nicht bestätigt'}</span>)
                                            </tr>
                                        ))}
                                    </td>
                                </>
                            )}



                            {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders2.length > 0 && (
                                <>
                                    <td>
                                        <th>
                                            <h5>Alternativ {selectedOrder.selectedMotorcycle2} auch gebucht:</h5>
                                        </th>
                                        {findOrdersWithSameMotorcycle(selectedOrder, orders).matchingOrders2.map(order => (
                                            <tr key={order.id} onClick={() => handleOrderClick(order)} style={{ cursor: 'pointer' }}>
                                                Buchung {order.id}: von {formatDate(order.startDate)} / {formatTime(order.startTime)} | bis {formatDate(order.endDate)} / {formatTime(order.endTime)} / (<span className={order.status ? 'confirmed' : 'not-confirmed'}>{order.status ? 'bestätigt' : 'nicht bestätigt'}</span>)
                                            </tr>
                                        ))}
                                    </td>
                                </>
                            )}

                        </div>














                    </div>









                </div>
            )}



        </div>
    );
};

export default Orders;
